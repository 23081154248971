import React from "react";
import { CalendarDay } from "../AppointmentBookingController/types";
import { t } from "../../../../../../locales";
import { classes } from "../../../../../../lib/templateHelpers";
import "./style.scss";

type Props = {
  className?: string;
  calendarDay: CalendarDay;
  onSelectDay: (day: Date) => void;
};

export default function AppointmentWeekDay({
  className,
  calendarDay: { enabled, dayOfWeek, date, dayOfMonth, initialCheckStatus },
  onSelectDay,
}: Props) {
  const cls = ["appointment-week-day"];
  if (className) cls.push(className);

  const id = `${dayOfMonth}_calendar-day`;

  return (
    <div className={cls.join(" ")}>
      <span className="appointment-week-day__name d-center fw-bold noselect">{t(dayOfWeek)}</span>
      <input
        className="appointment-week-day__radio"
        type="radio"
        name="calendarDay"
        id={id}
        defaultChecked={initialCheckStatus}
        onChange={() => onSelectDay(date)}
      />
      <label
        className={classes("appointment-week-day__label d-center fw-semibold", { __disabled: !enabled })}
        htmlFor={id}
      >
        {dayOfMonth}
      </label>
    </div>
  );
}
