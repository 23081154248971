import { add, eachDayOfInterval, set, sub } from "date-fns";
import { useRef, useState } from "react";
import { AppointmentSlotMap } from "../../../../../types";
import { RangeTime } from "../../../types";
import { CalendarDay } from "../../AppointmentBookingController/types";
import { getCalendarDays, getRangeTime, startWeekDayDate } from "../../../utils";
import { WEEK_LIMIT } from "../../AppointmentBookingController/consts";
import { LAST_DAY_OF_WEEK } from "../../../consts";

export function useCalendar(onChangeWeek: (range: RangeTime) => void, appointmentSlotMap?: AppointmentSlotMap) {
  const [activeDay, setActiveDay] = useState<Date>(new Date());
  const [calendarDays, setCalendarDays] = useState<CalendarDay[]>(() => {
    if (!appointmentSlotMap) return [];
    const initialDate = set(startWeekDayDate(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const dateList = eachDayOfInterval({
      start: initialDate,
      end: add(initialDate, { days: LAST_DAY_OF_WEEK }),
    });
    return getCalendarDays(activeDay, dateList, appointmentSlotMap);
  });

  const weekStep = useRef<number>(1);
  const setNextCalendarDays = (): void => {
    ++weekStep.current;
    const lastDayOfWeek = calendarDays[LAST_DAY_OF_WEEK].date;
    const dateList = eachDayOfInterval({
      start: add(lastDayOfWeek, { days: 1 }),
      end: add(lastDayOfWeek, { days: LAST_DAY_OF_WEEK + 1 }),
    });
    const days = getCalendarDays(activeDay, dateList, appointmentSlotMap ?? {});
    setCalendarDays(days);
    onChangeWeek(getRangeTime(days));
  };

  const setPrevCalendarDays = (): void => {
    --weekStep.current;
    const firstDayOfWeek = calendarDays[0].date;
    const dateList = eachDayOfInterval({
      start: sub(firstDayOfWeek, { days: LAST_DAY_OF_WEEK + 1 }),
      end: sub(firstDayOfWeek, { days: 1 }),
    });
    const days = getCalendarDays(activeDay, dateList, appointmentSlotMap ?? {});
    setCalendarDays(days);
    onChangeWeek(getRangeTime(days));
  };

  const updateCalendarDays = (): void => {
    if (!appointmentSlotMap) return;
    const dateList = eachDayOfInterval({
      start: calendarDays[0].date,
      end: calendarDays[LAST_DAY_OF_WEEK].date,
    });
    setCalendarDays(getCalendarDays(activeDay, dateList, appointmentSlotMap));
  };

  const canGoPrev = weekStep.current !== 1;
  const canGoNext = weekStep.current < WEEK_LIMIT;

  return {
    canGoPrev,
    canGoNext,
    activeDay,
    calendarDays,
    setActiveDay,
    updateCalendarDays,
    setNextCalendarDays,
    setPrevCalendarDays,
  };
}
