import React from "react";
import { useTranslation } from "../../locales";
import ModalWindow from "../../components/ModalWindow";
import BaseButton from "../../components/BaseButton";
import "./style.scss";

type Props = {
  className?: string;
  show: boolean;
  isCoach?: boolean;
  onClose: () => void;
};

export default function AppointmentDescriptionModal({ className, show, isCoach = false, onClose }: Props) {
  const cls = ["appointment-description"];
  if (className) cls.push(className);

  const { t } = useTranslation();

  return (
    <ModalWindow show={show} onClose={onClose}>
      <div className={cls.join(" ")}>
        <h3 className="appointment-description__title mb-1">{t("Cancellation")}</h3>
        <div className="appointment-description__text body mb-6">
          {t("The appointment can be cancelled no later than 24 hours before it starts.")}
        </div>
        <h3 className="appointment-description__title mb-1">{t("Missed appointment")}</h3>
        <div className="appointment-description__text body mb-1">
          {isCoach
            ? t("In case the Member missed the appointment, the funds will be transferred to your balance.")
            : t("In case the Specialist missed the appointment, the funds will be returned to your balance.")}
        </div>
        <div className="appointment-description__text body mb-6">
          {isCoach
            ? t("In case you missed the appointment, the funds will be returned to the Member.")
            : t("In case you missed the appointment, the funds will be transferred to the Specialist.")}
        </div>
        <BaseButton className="appointment-description__btn" fluid onClick={onClose}>
          {t("Got it")}
        </BaseButton>
      </div>
    </ModalWindow>
  );
}
