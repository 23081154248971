export const LAST_DAY_OF_WEEK = 6;
export let START_OF_WEEK: 0 | 1 = 1;
export const DAYS_OF_WEEK_LIST = [
  "shortSundaySymbol",
  "shortMondaySymbol",
  "shortTuesdaySymbol",
  "shortWednesdaySymbol",
  "shortThursdaySymbol",
  "shortFridaySymbol",
  "shortSaturdaySymbol",
];
