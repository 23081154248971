import React from "react";
import { CalendarDay } from "../AppointmentBookingController/types";
import AppointmentWeekDay from "../AppointmentWeekDay";
import "./style.scss";

type Props = {
  className?: string;
  calendarDayList: CalendarDay[];
  onSelectDay: (day: Date) => void;
};

export default function AppointmentWeekDayList({ className, calendarDayList, onSelectDay }: Props) {
  const cls = ["appointment-week-day-list"];
  if (className) cls.push(className);

  return (
    <ul className={cls.join(" ")}>
      {calendarDayList.map((item, index) => (
        <li className="appointment-week-day-list__item" key={`${item.dayOfMonth}_${index}`}>
          <AppointmentWeekDay calendarDay={item} onSelectDay={onSelectDay} />
        </li>
      ))}
    </ul>
  );
}
