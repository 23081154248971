import React, { useEffect, useRef } from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import emitter, { EVENTS } from "../../../../lib/emitter";
import BaseAvatar from "../../../../components/BaseAvatar";
import "./style.scss";
import { useTranslation } from "../../../../locales";

interface Props {
  user: UserPublicData;
  stream: MediaStream;
  hasVideo?: boolean;
  className?: string;
}

export default function ScreenStreamItem({ user, stream, hasVideo, className }: Props) {
  const { t } = useTranslation();
  const player = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (player.current) {
      player.current.pause();
      player.current.srcObject = stream;
      if (stream.getTracks().length && hasVideo) {
        player.current.play().catch((e) => {
          if (!player.current) throw e;
          setTimeout(
            () =>
              player.current?.play().catch((e) => {
                // eslint-disable-next-line no-console
                console.error(e);
                emitter.emit(EVENTS.USER_DID_NOT_INTERACT_DOC_FIRST, () => player.current?.play());
              }),
            500
          );
        });
      }
    }
  }, [hasVideo, stream]);
  const cls = ["screen-stream-item d-center"];
  if (!hasVideo) cls.push("__disabled-video");
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")}>
      <video className="screen-stream-item__video" ref={player} muted playsInline autoPlay />
      {!hasVideo && <BaseAvatar size={28} user={user} />}
      <div className="screen-stream-item__screen-name pa-1 truncate-ellipsis">
        {user.screenName} {t("(Presentation)")}
      </div>
    </div>
  );
}
