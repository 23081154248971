import { merge } from "lodash-es";
let config = {
  gtagIds: ["AW-10940438281", "G-NC7DNFDD7B"],
  facebookPixelId: "7283266625103112",
  apiUrl: process.env.REACT_APP_API_URL || "/graphql",
  backendUrl: process.env.REACT_APP_BACKEND_URL || "/api",
  s3Url: process.env.REACT_APP_S3_URL || "/files",
  publicS3Url: process.env.REACT_APP_PUBLIC_S3_URL || "/public-files",
  thumbnailUrl: process.env.REACT_APP_THUMBNAIL_URL || "/image-thumbnails",
  videoThumbnailUrl: process.env.REACT_APP_VIDEOTHUMBNAIL_URL || "/video-thumbnails",
  facebookAppId: "519046602188218",
  twitterCallbackUrl:
    process.env.REACT_APP_TWITTER_CALLBACK_URL ||
    `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ":" + window.location.port : ""
    }/login/twitter`,
  userJoinRole: "customer",
  userPerformerRole: "performer",
  auth: {
    facebook: false,
    twitter: false,
  },
  fileTypes: {
    image: [
      "jpg",
      "jpeg",
      "jpe",
      "heif",
      "heic",
      "png",
      "webp",
      "jxr",
      "jif",
      "jfif",
      "jfi",
      "jp2",
      "j2k",
      "jpf",
      "jpx",
      "jpm",
    ],
    video: ["webm", "mkv", "mov", "qt", "wmv", "mp4", "m4v", "mpg", "mpeg", "mpv", "avi"],
    audio: ["mp3", "aac", "ogg", "m4a"],
    document: ["pdf", "doc", "docx"],
  },
  maxFileSizeUpload: 2147483648,
  questionnaireFields: [
    {
      type: "textarea" as const,
      label: "Experience",
      name: "experience",
      placeholder: "",
    },
    {
      type: "textarea" as const,
      label: "Education",
      name: "education",
      placeholder: "",
    },
  ],
  technicalCategoryId: "3",
  featureCustomDomain: false,
};

export const setConfig = (override: Record<string, any>) => {
  config = merge(config, override);
};

export default config;
