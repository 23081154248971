import React from "react";
import { t } from "../../../../../../locales";
import useBreakPoints from "../../../../../../hooks/useBreakPoints";
import BaseButton from "../../../../../../components/BaseButton";
import PageLoading from "../../../../../../components/ElementLoading";
import BaseDivider from "../../../../../../components/BaseDivider";
import useViewController from "./viewController";
import AvailabilityIntervals from "../AvailabilityIntervals";
import "./style.scss";

const AppointmentsAvailability = () => {
  const {
    processing,
    loading,
    displayTimezone,
    showAddHours,
    availabilitySlots,
    onRemoveInterval,
    onRemoveIntervals,
    onBeginTimeChange,
    onEndTimeChange,
    addDefaultInterval,
    onSave,
  } = useViewController();

  const { desktopUp } = useBreakPoints();
  return (
    <div className="appointments-availability d-flex __column ml-auto mr-auto">
      {loading ? (
        <PageLoading loading={loading} />
      ) : (
        <>
          <div className="appointments-availability__timezone body caption mb-3">
            {t("Your timezone")}
            <span className="fw-semibold caption__mark"> {displayTimezone}</span>
          </div>
          <div className="appointments-availability__description body caption mb-3">
            <span>
              {t(
                'Set up your available hours for appointments. You are able to deselect specific slots in "Calendar" tab.'
              )}
            </span>
          </div>
          <div className="appointments-availability__intervals-wrapper d-flex __column mt-3">
            {Object.entries(availabilitySlots).map(([key, value], i) => (
              <React.Fragment key={"interval_" + i}>
                {!!i && <BaseDivider className="my-3" />}
                <AvailabilityIntervals
                  className="__interval"
                  dayOfWeek={+key}
                  intervals={value}
                  onChangeBeginTime={onBeginTimeChange}
                  onChangeEndTime={onEndTimeChange}
                  onRemove={onRemoveInterval}
                  onMultipleRemove={onRemoveIntervals}
                  onNewInterval={addDefaultInterval}
                  withLabels={!showAddHours || !desktopUp}
                />
              </React.Fragment>
            ))}
          </div>
          <div className="appointments-availability__save-button-wrapper d-flex">
            <BaseButton
              className="appointments-availability__save-button"
              onClick={() => onSave()}
              loading={processing}
              large
            >
              {t("Save Changes")}
            </BaseButton>
          </div>
        </>
      )}
    </div>
  );
};

export default AppointmentsAvailability;
