import React, { useRef } from "react";
import useComponentVisible from "../../hooks/useComponentVisible";
import useMounted from "../../hooks/useMounted";
import "./style.scss";

interface Props {
  className?: string;
  activator?: JSX.Element;
  children?: string | JSX.Element;
  text: string;
  enableHint?: boolean;
  position?: "top" | "bottom" | "left" | "right";
}

export default function BaseHint({ className = "", text, children, activator, enableHint, position = "top" }: Props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const isMounted = useMounted();

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const toggleHint = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
    if (isComponentVisible) {
      setIsComponentVisible(false);
    } else {
      setIsComponentVisible(true);
      timeout.current = setTimeout(() => {
        if (isMounted.current) setIsComponentVisible(false);
      }, 3000);
    }
  };

  return (
    <>
      {enableHint ? (
        <div className={`base-hint ${className} ${isComponentVisible ? "__active" : ""}`} ref={ref}>
          {activator ? (
            <>
              {children}
              <div className="base-hint__activator" onClick={(e) => toggleHint(e)}>
                {activator}
              </div>
            </>
          ) : (
            <div className="base-hint__activator" onClick={(e) => toggleHint(e)}>
              {children}
            </div>
          )}
          <div className={`base-hint__hint __${position}`}>{text}</div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
}
