import { OptionItem } from "../../../../../../types";
import { t } from "../../../../../../locales";
import { DayOfWeek, UserAvailabilityDisplayInterval } from "../AppointmentsAvailability/types";

const useSelectTimeRange = () => {
  const RANGE_12_HOURS = 12;
  const RANGE_24_HOURS = 24;
  const RANGE_STEPS = ["00", "30"];
  const getHoursString = (hour: number): string => (hour > 9 ? `${hour}` : `0${hour}`);

  const range24HoursOptions: OptionItem[] = new Array(RANGE_24_HOURS).fill("").reduce((acc, _, i) => {
    const hour = getHoursString(i);
    RANGE_STEPS.forEach((s) => acc.push({ text: `${hour}:${s}`, value: `${hour}:${s}` }));
    return acc;
  }, []);
  const range12HoursOptions: OptionItem[] = range24HoursOptions.map((o) => {
    const hours = parseInt(o.text.slice(0, 2));
    const hours12 = hours % RANGE_12_HOURS || RANGE_12_HOURS;
    const mins = o.text.slice(-2);
    const AMPM = hours >= RANGE_12_HOURS ? "PM" : "AM";

    return {
      text: `${getHoursString(hours12)}:${mins} ${AMPM}`,
      value: o.value,
    };
  });

  return {
    range12HoursOptions,
  };
};

const useViewController = () => {
  const { range12HoursOptions } = useSelectTimeRange();

  const daysOfWeekItems: OptionItem[] = [
    { value: DayOfWeek.Monday, text: t("Monday") },
    { value: DayOfWeek.Tuesday, text: t("Tuesday") },
    { value: DayOfWeek.Wednesday, text: t("Wednesday") },
    { value: DayOfWeek.Thursday, text: t("Thursday") },
    { value: DayOfWeek.Friday, text: t("Friday") },
    { value: DayOfWeek.Saturday, text: t("Saturday") },
    { value: DayOfWeek.Sunday, text: t("Sunday") },
  ];

  const selectOptionAdapter = (interval: UserAvailabilityDisplayInterval) => {
    return {
      startTime: `${interval.beginTime.hours}:${interval.beginTime.minutes}`,
      endTime: `${interval.endTime.hours}:${interval.endTime.minutes}`,
    };
  };

  return {
    daysOfWeekItems,
    range12HoursOptions,
    selectOptionAdapter,
  };
};

export default useViewController;
