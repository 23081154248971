import { useMemo, useState } from "react";
import { checkRole } from "@scrile/tools/dist/lib/SecurityHelpers";
import { useTranslation } from "../../locales";
import { LOGO_URL } from "../../lib/urlHelper";
import { BaseMenuItemInterface } from "../../types";
import useController from "./controller";
import config from "../../config";

function useViewController() {
  const { onClickMemberBalance, user, unreadThreadsCount, isPerformer, countUpcomingEvents } = useController();
  const { t } = useTranslation();
  const [logoSrc, setLogoSrc] = useState(LOGO_URL);
  const isCustomer = useMemo(() => checkRole(user?.role ?? "", config.userJoinRole), [user]);

  const setDefaultLogo = () => {
    setLogoSrc(process.env.PUBLIC_URL + "/images/logo.svg");
  };

  const itemsList: BaseMenuItemInterface[] = [
    {
      name: "appointments",
      icon: "available",
      title: t("Appointments"),
      to: "/user/appointments",
    },
    {
      name: "settings",
      icon: "settings",
      title: t("Settings"),
      to: "/user/settings",
      className: "__divider",
      divider: true,
    },
    {
      name: "logout",
      icon: "logout",
      title: t("Log out"),
      to: "/logout",
    },
  ];

  if (checkRole(user?.role ?? "", config.userPerformerRole))
    itemsList.splice(0, 0, {
      name: "profile",
      icon: "profile",
      title: t("Profile"),
      to: "/" + user?.username,
    });

  return {
    logoSrc,
    isCustomer,
    setDefaultLogo,
    isPerformer,
    countUpcomingEvents,
    itemsList,
    onClickMemberBalance,
    user,
    unreadThreadsCount,
  };
}

export default useViewController;
