import { useCallback, useEffect } from "react";
import { useLocation } from "react-use";
import { NotificationType } from "@scrile/api-provider/dist/api/NotificationsProvider";
import { isAppointmentReminderNotificationDetails } from "@scrile/api-provider/dist/api/AppointmentProvider";
import emitter, { EVENTS } from "../../../../../lib/emitter";
import { Props, SystemNotificationFactory } from "../types";

export default function ({ addHandler, removeHandler }: Props) {
  const location = useLocation();

  const handler = useCallback<SystemNotificationFactory>(
    (notification) => {
      if (
        isAppointmentReminderNotificationDetails(notification.details) &&
        (notification.type === NotificationType.APPOINTMENT_REMINDER_CONSUMER ||
          notification.type === NotificationType.APPOINTMENT_REMINDER_PROVIDER) &&
        !location.pathname?.includes(`/appointment/${notification.details.appointment.id}`)
      ) {
        setTimeout(() => {
          emitter.emit(EVENTS.APPOINTMENT_REMINDER, notification.details.appointment);
        }, notification.details.minutesRemaining * 60000)
      }
      return false;
    },
    [location.pathname]
  );

  useEffect(() => {
    addHandler("appointmentRemindNotification", handler);
    return () => removeHandler("appointmentRemindNotification");
  }, [addHandler, handler, removeHandler]);

  return null;
}
