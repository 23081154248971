import React from "react";
import { useHistory } from "react-router-dom";
import useAuthUser from "../../hooks/useAuthUser";
import JoinLiveChatModal from "../../components/JoinLiveChatModal";

function GoAppointmentChatModal({ onClose, appointmentId }: { onClose: () => void; appointmentId: string }) {
  const history = useHistory();
  const { user } = useAuthUser();

  const onClickStart = () => {
    history.push(`/appointment/${appointmentId}`);
  };

  if (!appointmentId || !user) {
    Promise.resolve().then(() => onClose())
    return null;
  }

  return <JoinLiveChatModal className={"go-appointment-chat-modal"} onClose={onClose} onSubmit={onClickStart} />;
}

export default GoAppointmentChatModal;
