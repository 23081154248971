import { useCallback, useEffect, useState } from "react";
import { usePrevious } from "react-use";
import { throttle } from "lodash-es";
import emitter, { EVENTS } from "../../lib/emitter";
import { AppointmentStatus } from "@scrile/api-provider/dist/api/AppointmentProvider";
import useAuthUser from "../../hooks/useAuthUser";
import providers from "../../lib/providers";
import config from "../../config";

export default function useController() {
  const { user } = useAuthUser();
  const prevUser = usePrevious(user);

  const [unreadThreadsCount, setUnreadThreadsCount] = useState(0);

  const [countUpcomingEvents, setCountUpcomingEvents] = useState(0);
  const isPerformer: boolean = user?.role === config.userPerformerRole;

  const getUpcomingEvents = async () => {
    const response = await providers.AppointmentProvider.search({
      ...(isPerformer ? { providerId: user?.id } : { consumerId: user?.id }),
      startTimeFrom: new Date().toISOString(),
      status: [AppointmentStatus.NEW, AppointmentStatus.APPROVED],
      size: 0,
    });
    setCountUpcomingEvents(response.totalRecords);
  };

  const throttledCountOfThreadListener = useCallback(
    throttle(() => {
      providers.MessageThreadsProvider.getUnreadCount().then((r) => setUnreadThreadsCount(r));
    }, 1000),
    []
  );

  useEffect(() => {
    if (!prevUser && user) {
      throttledCountOfThreadListener();
      getUpcomingEvents();
      emitter.on(EVENTS.MESSAGES_NEW_MESSAGE, throttledCountOfThreadListener);
      emitter.on(EVENTS.MESSAGES_MARKED_READ, throttledCountOfThreadListener);
    } else if (prevUser && !user) {
      emitter.off(EVENTS.MESSAGES_NEW_MESSAGE, throttledCountOfThreadListener);
      emitter.off(EVENTS.MESSAGES_MARKED_READ, throttledCountOfThreadListener);
    }

    return () => {
      emitter.off(EVENTS.MESSAGES_NEW_MESSAGE, throttledCountOfThreadListener);
      emitter.off(EVENTS.MESSAGES_MARKED_READ, throttledCountOfThreadListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, throttledCountOfThreadListener]);

  const onClickMemberBalance = () => {
    emitter.emit(EVENTS.BALANCE_REFILL_SHOW);
  };

  return {
    onClickMemberBalance,
    isPerformer,
    countUpcomingEvents,
    user,
    unreadThreadsCount,
  };
}
