import React, { useMemo } from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { checkRole } from "@scrile/tools/dist/lib/SecurityHelpers";
import { useTranslation } from "../../../../locales";
import { getDate } from "../../../../lib/timeHelper";
import useBreakPoints from "../../../../hooks/useBreakPoints";
import useCustomDomain from "../../../../hooks/useCustomDomain";
import useMessages from "../../../../hooks/useMessages";
import useAuthUser from "../../../../hooks/useAuthUser";
import config from "../../../../config";
import BaseIcon from "../../../../components/BaseIcon";
import CardAvatar from "../../../../components/CardAvatar";
import BaseCard from "../../../../components/BaseCard";
import BaseTags from "../../../../components/BaseTags";
import AddToFavorite from "../../../../components/AddToFavorite";
import BaseButton from "../../../../components/BaseButton";
import "./style.scss";

interface Props {
  user: UserPublicData;
  className?: string;
  hasAppointment?: boolean;
  onBookClick: () => void;
}

function PerformerProfileCard({ user, className, hasAppointment, onBookClick }: Props) {
  const { user: authUser } = useAuthUser();
  const { t } = useTranslation();
  const { tabletUp: isTablet, desktopUp } = useBreakPoints();
  const isCustomer = useMemo(() => checkRole(authUser?.role ?? "", config.userJoinRole), [authUser]);
  const { isCustomDomain, user: userFromDomain } = useCustomDomain();
  const { getMessageUrl } = useMessages();

  const cls = ["performer-profile"];
  if (className) cls.push(className);

  const educations = user.questionary?.fields["academic"]?.educations || [];
  const jobs = user.questionary?.fields["professional"]?.jobs || [];

  const educationList = educations.map((item) => {
    const dateFrom = item.from ? getDate(new Date(item.from?.year, item.from?.month).toISOString()) : null;
    const dateTo = item.to ? getDate(new Date(item.to?.year, item.to?.month).toISOString()) : null;
    return (
      <div className="performer-profile__questionnaire-item" key={item.degree}>
        <h4 className="performer-profile__questionnaire-item-title body-headline mb-2">
          {item.university}, {item.degree}
        </h4>
        <div className="performer-profile__questionnaire-item-data body">
          {`${dateFrom?.month}, ${dateFrom?.year} – `}
          {dateTo ? `${dateTo.month}, ${dateTo.year}` : t("Present")}
        </div>
        <div className="performer-profile__questionnaire-item-location body">{item.location}</div>
      </div>
    );
  });

  const jobList = jobs.map((item) => {
    const dateFrom = item.from ? getDate(new Date(item.from?.year, item.from?.month).toISOString()) : null;
    const dateTo = item.to ? getDate(new Date(item.to?.year, item.to?.month).toISOString()) : null;
    return (
      <div className="performer-profile__questionnaire-item" key={item.firm}>
        <h4 className="performer-profile__questionnaire-item-title body-headline mb-2">
          {item.title}, {item.firm}
        </h4>
        <div className="performer-profile__questionnaire-item-data body">
          {`${dateFrom?.month}, ${dateFrom?.year} – `}
          {dateTo ? `${dateTo.month}, ${dateTo.year}` : t("Present")}
        </div>
        <div className="performer-profile__questionnaire-item-location body">{item.location}</div>
      </div>
    );
  });

  return (
    <BaseCard className={cls.join(" ")}>
      <div className="performer-profile__inner">
        <div className="performer-profile__top">
          {!isCustomDomain && <h3 className="performer-profile__name headline-2">{user.screenName}</h3>}
          {isTablet && !isCustomDomain && (
            <div className="performer-profile__status-line">
              <AddToFavorite className="performer-profile__favorite" withText={isTablet} user={user} />
            </div>
          )}
        </div>
        {!isCustomDomain && user.questionary?.fields?.location && (
          <div className="performer-profile__location">
            <BaseIcon className="performer-profile__location-icon">location</BaseIcon>
            <span className="performer-profile__location-text body">
              {user.questionary?.fields?.location as string}
            </span>
          </div>
        )}
        <div className="performer-profile__container">
          <CardAvatar
            to={
              user.publicLivechatUser?.broadcasting ? `${user.id === userFromDomain?.id ? "" : user.username}/live` : ""
            }
            className="performer-profile__avatar-container"
            user={user}
            classNameAvatar={`performer-profile__avatar${isCustomDomain ? " __custom" : ""}`}
          />
          {user.questionary?.fields.about && (
            <div className="performer-profile__about">
              <h4 className="performer-profile__about-header body-big">{t("About me")}</h4>
              {isCustomDomain && user.questionary?.fields?.location && (
                <div className="performer-profile__location  __custom">
                  <BaseIcon className="performer-profile__location-icon">location</BaseIcon>
                  <span className="performer-profile__location-text body">
                    {user.questionary?.fields?.location as string}
                  </span>
                </div>
              )}
              <div className="performer-profile__about-text body">{user.questionary?.fields?.about as string}</div>
            </div>
          )}
        </div>
        {!!user.questionary?.tags?.length && (
          <div className="performer-profile__practice-area">
            <BaseTags className="performer-profile__practice-area-tags" user={user} />
          </div>
        )}

        {!desktopUp && isCustomer && (
          <div className="performer-profile__button-area mt-8">
            <BaseButton to={getMessageUrl(user)} fluid outline>
              {t("Ask a question")}
            </BaseButton>
            {hasAppointment && (
              <BaseButton className="mt-auto" fluid onClick={onBookClick}>
                {t("Book")}
              </BaseButton>
            )}
          </div>
        )}

        <div className="performer-profile__questionnaire">
          {!!jobList.length && (
            <div className="performer-profile__questionnaire-professional">
              <h3 className="performer-profile__questionnaire-headline body-headline mb-3 d-flex __align-center">
                <BaseIcon className="mr-2">available</BaseIcon>
                {t("Professional")}
              </h3>
              {jobList}
            </div>
          )}
          {!!educationList.length && (
            <div className="performer-profile__questionnaire-academic">
              <h3 className="performer-profile__questionnaire-headline body-headline mb-3 d-flex __align-center">
                <BaseIcon className="mr-2">academic</BaseIcon>
                {t("Academic")}
              </h3>
              {educationList}
            </div>
          )}
        </div>
      </div>
    </BaseCard>
  );
}

export default PerformerProfileCard;
