import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { EVENT_NAMES } from "@scrile/api-provider/dist";
import emitter from "./lib/emitter";
import { getLoginData } from "./lib/authHelper";
import useAuthUser, { AuthUserContextProvider } from "./hooks/useAuthUser";
import useCustomDomain, { CustomDomainContextProvider } from "./hooks/useCustomDomain";
import useCategories, { CategoriesContextProvider } from "./hooks/useCategories";
import { AuthUserVerificationContextProvider } from "./hooks/useAuthUserVerificationStatus";
import { AppStateContextProvider } from "./hooks/useAppState";
import PageHeader from "./components/PageHeader";
import PageFooter from "./components/PageFooter";
import ModalBalanceRefill from "./modals/ModalBalanceRefill";
import RouteController from "./router/RouteController";
import MainRouterView from "./router/MainRouterView";
import PushManager from "./components/PushManager";
import AppointmentBanner from "./components/AppointmentBanner";
import "./styles/App.scss";

function App() {
  const { setLoginData } = useAuthUser();
  const { loadData: loadCustomDomainInfo, isLoading: isLoadingCustomDomainInfo } = useCustomDomain();
  const { loadData: loadCategories } = useCategories();

  useEffect(() => {
    const onEvent = (data: any) => {
      const oldData = getLoginData();
      if (JSON.stringify(data) === JSON.stringify(oldData)) return;
      setLoginData(data);
    };
    emitter.on(EVENT_NAMES.SET_USER, onEvent);
    return () => emitter.off(EVENT_NAMES.SET_USER, onEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadCustomDomainInfo();
    loadCategories();
  }, [loadCustomDomainInfo, loadCategories]);

  if (isLoadingCustomDomainInfo) return null;

  return (
    <>
      <PageHeader />
      <AppointmentBanner />
      <main className="main">
        <div className="container">
          <MainRouterView />
        </div>
      </main>
      <PageFooter />
      <RouteController />
      <PushManager />
    </>
  );
}

export function WrappedApp() {
  return (
    <AuthUserContextProvider>
      <AuthUserVerificationContextProvider>
        <AppStateContextProvider>
          <CustomDomainContextProvider>
            <CategoriesContextProvider>
              <App />
              <ModalBalanceRefill />
            </CategoriesContextProvider>
          </CustomDomainContextProvider>
        </AppStateContextProvider>
      </AuthUserVerificationContextProvider>
    </AuthUserContextProvider>
  );
}

export default function BrowserApp() {
  return (
    <Router>
      <WrappedApp />
    </Router>
  );
}
