import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { NavigationMenuItemInterface } from "../../../../types";
import { t } from "../../../../locales";
import BaseCard from "../../../../components/BaseCard";
import AppointmentsAvailability from "./components/AppointmentsAvailability";
import AppointmentsReserve from "./components/AppointmentsReserve";
import NavigationTabs from "../NavigationTabs";
import "./style.scss";

const CalendarPage = () => {
  const match = useRouteMatch();

  const topNavigationMenu: NavigationMenuItemInterface[] = [
    {
      title: t("Calendar"),
      name: "calendar",
      to: "/user/calendar",
    },
    {
      title: t("Settings"),
      name: "settings",
      to: "/user/calendar/settings",
    },
  ];

  return (
    <BaseCard className="calendar-page">
      <NavigationTabs itemsList={topNavigationMenu} />
      <div className="calendar-page__wrapper">
        <Switch>
          <Route exact path={`${match.url}`}>
            <AppointmentsReserve />
          </Route>
          <Route path={`${match.url}/settings`}>
            <AppointmentsAvailability />
          </Route>
          <Route path={`*`}>
            <Redirect to={`${match.url}/settings`} />
          </Route>
        </Switch>
      </div>
    </BaseCard>
  );
};

export default CalendarPage;
