import React from "react";
import "./style.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
}

export default function BaseCard({ children, className, dataTestId }: Props) {
  const cls = ["base-card"];
  if (className) cls.push(className);
  return <div className={cls.join(" ")} data-testid={dataTestId}>{children}</div>;
}
