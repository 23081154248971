import { useEffect, useRef, useState } from "react";
import { isMobile } from "@scrile/tools/dist/lib/browserUtils";
import { debounce } from "lodash";
import useBreakPoints from "../../../../hooks/useBreakPoints";
import useMounted from "../../../../hooks/useMounted";
import { MediaConstraints } from "../../../../types";
import useResizeVideo from "../../../../pages/PageAppointmentChat/components/SecondStreamView/hooks/useResizeVideo";
import useController from "./controller";

interface Props {
  stream: MediaStream;
  constraints: MediaConstraints;
  onChangeConstraints: (constraints: MediaConstraints) => void;
}

interface DisplayControls {
  controls: boolean;
}

function useViewController({ stream, constraints, onChangeConstraints }: Props) {
  const player = useRef<HTMLVideoElement>(null);
  const streamView = useRef<HTMLDivElement>(null);

  const [showStreamPlayButton, setShowStreamPlayButton] = useState<boolean>(false);
  const [cb, setCb] = useState<() => void>();

  useResizeVideo({
    player: player,
    videoWrapper: streamView,
    excludeParameter: ["height"],
  });

  const debounceTime = 200;

  const [displayControls, setDisplayControls] = useState<DisplayControls>({
    controls: false,
  });

  const { desktopUp, mobileLandscape } = useBreakPoints();

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isMounted = useMounted();

  const { onSwitchCam, facingMode } = useController(onChangeConstraints, constraints);

  const changeControls = (name: keyof DisplayControls, state: boolean) => {
    setDisplayControls((settings) => ({
      ...settings,
      [name]: state,
    }));
  };

  const onShowControls = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
    if (displayControls.controls) {
      changeControls("controls", false);
    } else {
      changeControls("controls", true);
      timeout.current = setTimeout(() => {
        if (isMounted.current) changeControls("controls", false);
      }, 3000);
    }
  };

  const onToggleCam = debounce(async () => {
    if (!onChangeConstraints || !constraints) return;
    onChangeConstraints({ videoEnabled: !constraints.videoEnabled });
  }, debounceTime);

  const onToggleMic = debounce(async () => {
    if (!onChangeConstraints || !constraints) return;
    onChangeConstraints({ audioEnabled: !constraints.audioEnabled });
  }, debounceTime);

  const onToggleBlur = debounce(async () => {
    if (!onChangeConstraints || !constraints) return;
    onChangeConstraints({ blurEnabled: !constraints.blurEnabled });
  }, debounceTime);

  const playStream = () => {
    if (cb) {
      cb();
      setCb(undefined);
    } else if (player.current) {
      player.current.muted = false;
      setShowStreamPlayButton(false);
      player.current.play().then();
    }
  };

  useEffect(() => {
    (() => {
      if (!player.current) return;
      player.current.pause();
      player.current.srcObject = stream;
      player.current.oncanplay = () => {
        if (!player.current) return;
        player.current.play().catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
          if (!player.current) return;
          player.current.muted = true;
          player.current.play().catch(() => {
            setShowStreamPlayButton(true);
          });
        });
      };
    })();
  }, [stream, player]);

  return {
    player,
    mobileLandscape,
    onShowControls,
    onToggleCam,
    onToggleMic,
    onToggleBlur,
    showControls: displayControls.controls || desktopUp,
    showControlsButtons:
      ((displayControls.controls || desktopUp) && !isMobile()) ||
      ((displayControls.controls || desktopUp) && isMobile()),
    showStreamPlayButton,
    displayControls,
    changeControls,
    onSwitchCam,
    facingMode,
    desktopUp,
    hasVideo: constraints?.videoEnabled ?? true,
    hasAudio: constraints?.audioEnabled ?? true,
    streamView,
    playStream,
  };
}

export default useViewController;
