import React, { useMemo } from "react";
import { Transaction } from "@scrile/api-provider/dist/api/TransactionProvider";
import { useTranslation } from "../../../../../locales";
import DefaultTransaction from "../DefaultTransaction";
import { AppointmentStatus } from "@scrile/api-provider/dist/api/AppointmentProvider";

interface Props {
  transaction: Transaction;
}

function TransactionAppointment({ transaction }: Props) {
  const { t } = useTranslation();

  const transactionName = useMemo(() => {
    switch (transaction.type) {
      case "EARNING":
        return t("Completed Session");
      case "SPENDING":
        return transaction.details.appointment.status === AppointmentStatus.COMPLETED
          ? t("Completed Session")
          : t("Booked session");
      case "REFUND":
        return transaction.details.appointment.status === AppointmentStatus.NOSHOW
          ? t("Refund")
          : t("Cancelled Session");
    }
  }, [transaction, t]);

  return (
    <DefaultTransaction
      transaction={transaction}
      type={transactionName}
      description={<>{transaction.details.description}</>}
    />
  );
}

export default TransactionAppointment;
