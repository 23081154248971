import { AppointmentType } from "@scrile/api-provider/dist/api/AppointmentTypeProvider";
import { AppointmentSlot } from "@scrile/api-provider/dist/api/AppointmentSlotProvider";
import { formatAppointmentDate, getDuration } from "../../../lib/timeHelper";
import { t } from "../../../locales";
import { getPrice } from "../../../lib/moneyHelper";

export default function viewController(
  amount: number,
  screenName: string,
  isBooked: boolean,
  isNotEnoughBalance: boolean,
  appointmentType: AppointmentType | null,
  startTime?: AppointmentSlot["startTime"]
) {
  const price = getPrice(amount);
  const duration = getDuration(appointmentType?.duration ?? 0);
  const statusText = isBooked
    ? t("You have booked an appointment with {{screenName}}", { screenName: screenName })
    : t("Appointment with {{screenName}}", { screenName: screenName });
  const buttonText = isNotEnoughBalance
    ? t("Refill balance")
    : isBooked
    ? t("Upcoming appointments")
    : t("Purchase for {{price}}", { price });
  const title = isBooked ? t("Booking confirmed") : t("Confirm booking");
  const startDate = formatAppointmentDate(new Date(startTime ?? ""));

  return {
    price,
    title,
    duration,
    startDate,
    statusText,
    buttonText,
  };
}
