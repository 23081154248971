import React, { useEffect } from "react";
import { useMount } from "react-use";
import { AppointmentSlotMap } from "../../../../types";
import { useCalendar } from "./hooks/useCalendar";
import BaseButton from "../../../../../../components/BaseButton";
import BaseIcon from "../../../../../../components/BaseIcon";
import { getCalendarTitle } from "../../../../../../lib/timeHelper";
import { RangeTime } from "../../types";
import { getRangeTime } from "../../utils";
import AppointmentWeekDayList from "../AppointmentWeekDayList";
import "./style.scss";

type Props = {
  className?: string;
  loading?: boolean;
  appointmentSlotMap?: AppointmentSlotMap;
  onChangeWeek: (range: RangeTime) => void;
  onSelectDay: (day: Date) => void;
};

export default function AppointmentCalendar({
  className,
  loading = false,
  appointmentSlotMap,
  onSelectDay,
  onChangeWeek,
}: Props) {
  const cls = ["appointment-calendar mb-5"];
  if (className) cls.push(className);

  const {
    canGoPrev,
    canGoNext,
    activeDay,
    calendarDays,
    setActiveDay,
    updateCalendarDays,
    setNextCalendarDays,
    setPrevCalendarDays,
  } = useCalendar(onChangeWeek, appointmentSlotMap);

  const handleDaySelect = (day: Date): void => {
    setActiveDay(day);
    !!onSelectDay && onSelectDay(day);
  };

  const title = getCalendarTitle(calendarDays[0].date);

  useEffect(() => {
    updateCalendarDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentSlotMap]);
  useMount(() => {
    onChangeWeek(getRangeTime(calendarDays));
    onSelectDay(activeDay);
  });

  return (
    <div className={cls.join(" ")}>
      <div className="appointment-calendar__header mb-2">
        <h4 className="appointment-calendar__title fw-semibold">{title}</h4>

        <BaseButton
          className="appointment-calendar__slide-btn mr-3"
          disabled={loading || !canGoPrev}
          round
          borderless
          dataTestId="calendar-prev-week"
          onClick={setPrevCalendarDays}
        >
          <BaseIcon>back</BaseIcon>
        </BaseButton>
        <BaseButton
          className="appointment-calendar__slide-btn __next"
          disabled={loading || !canGoNext}
          round
          borderless
          onClick={setNextCalendarDays}
          dataTestId="calendar-next-week"
        >
          <BaseIcon>back</BaseIcon>
        </BaseButton>
      </div>

      <AppointmentWeekDayList calendarDayList={calendarDays} onSelectDay={handleDaySelect} />
    </div>
  );
}
