import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { add, getDayOfYear } from "date-fns";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { AppointmentType } from "@scrile/api-provider/dist/api/AppointmentTypeProvider";
import { PaymentPackage } from "@scrile/api-provider/dist/api/PaymentPackageProvider";
import { AppointmentSlot, AppointmentSlotStatus } from "@scrile/api-provider/dist/api/AppointmentSlotProvider";
import { t } from "../../../../../../locales";
import useMessages from "../../../../../../hooks/useMessages";
import useAppointmentSlot from "../../../../hooks/useAppointmentSlots";
import useAppointmentBook from "./hooks/useAppointmentBook";
import BaseButton from "../../../../../../components/BaseButton";
import BaseCard from "../../../../../../components/BaseCard";
import BaseSpinner from "../../../../../../components/BaseSpinner";
import { BookingData, RangeTime } from "../../types";
import { PriceDate } from "./types";
import AppointmentDurationPrice from "../AppointmentDurationPrice";
import AppointmentCalendar from "../AppointmentCalendar";
import AppointmentSlotList from "../AppointmentSlotList";
import "./style.scss";

interface Props {
  user: UserPublicData;
  appointmentTypes: AppointmentType[];
  paymentPackages: PaymentPackage[];
  loading: boolean;
  onBookClick?: (data: BookingData) => void;
  onDescriptionClick?: () => void;
  getResetCallback?: (cb: () => void) => void;
}

function AppointmentBookingController({
  user,
  loading,
  appointmentTypes,
  paymentPackages,
  onBookClick,
  onDescriptionClick,
  getResetCallback,
}: Props) {
  const cls = ["appointment-booking d-flex __column"];

  const { getMessageUrl } = useMessages();
  const [interactedSlots, setInteractedSlots] = useState<Record<string, boolean>>({});
  const { loading: slotLoading, appointmentSlotMap, getAppointmentSlots } = useAppointmentSlot();
  const {
    bookTime,
    keyOfActiveDay,
    selectedRangeTime,
    currentDurationPrice,
    setBookTime,
    getBookButtonText,
    setKeyOfActiveDay,
    getAppointmentTypeId,
    setCurrentDurationPrice,
  } = useAppointmentBook(appointmentTypes);

  const handleWeekChange = (range: RangeTime): void => {
    selectedRangeTime.current = range;
    setBookTime(null);
    getAppointmentSlots({
      startTime: range.startTime.toISOString(),
      endTime: add(range.endTime, { days: 1 }).toISOString(),
      appointmentTypeId: getAppointmentTypeId(),
      userId: user.id,
    });
  };

  const handleSlotSelect = (slot: AppointmentSlot | null): void => {
    if (!appointmentSlotMap || !slot || bookTime === slot.startTime || slot.status === AppointmentSlotStatus.TOO_SHORT)
      return;
    const slotIndex = appointmentSlotMap[keyOfActiveDay].findIndex((_) => _.startTime === slot.startTime);
    if (slotIndex === -1) return;
    setInteractedSlots({
      [appointmentSlotMap[keyOfActiveDay][slotIndex].startTime]: true,
      [appointmentSlotMap[keyOfActiveDay][slotIndex + 1].startTime]: true,
    });
    setBookTime(slot.startTime);
  };

  const handleDaySelect = (day: Date): void => {
    setKeyOfActiveDay(getDayOfYear(day));
    setBookTime(null);
  };

  const appointmentType = useRef<AppointmentType | null>(null);
  const handlePriceChange = (priceData: PriceDate) => {
    setBookTime(null);
    appointmentType.current = priceData.appointmentType;
    setCurrentDurationPrice(priceData.paymentPackage);
    reset();
  };
  const appointmentsAvailable = appointmentTypes.length > 0 && paymentPackages.length > 0 && !loading;
  const showCalendar = appointmentsAvailable && currentDurationPrice;
  const askLink = getMessageUrl(user);
  const bookingData: BookingData = {
    startTime: bookTime ?? undefined,
    amount: currentDurationPrice?.amount ?? 0,
    appointmentType: appointmentType.current ?? null,
  };

  const reset = useCallback((): void => {
    setBookTime(null);
    if (selectedRangeTime.current && appointmentType.current) {
      getAppointmentSlots({
        startTime: selectedRangeTime.current.startTime.toISOString(),
        endTime: add(selectedRangeTime.current.endTime, { days: 1 }).toISOString(),
        appointmentTypeId: appointmentType.current?.id,
        userId: user.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRangeTime.current, appointmentType.current, user.id]);

  useEffect(() => {
    !!getResetCallback && getResetCallback(reset);
  }, [getResetCallback, reset]);

  return (
    <Fragment>
      <BaseCard className={cls.join(" ")} dataTestId="appointment-booking">
        <div className="appointment-booking__content mb-5 pb-5">
          <h3 className="appointment-booking__heading text-center headline-4 mb-5">{t("Book appointment")}</h3>
          {loading && <BaseSpinner className="ma-auto" />}

          {appointmentsAvailable && (
            <AppointmentDurationPrice
              className="mb-5"
              appointmentTypes={appointmentTypes}
              paymentPackages={paymentPackages}
              onChangePrice={handlePriceChange}
            />
          )}

          {showCalendar && (
            <Fragment>
              <AppointmentCalendar
                appointmentSlotMap={appointmentSlotMap}
                loading={slotLoading}
                onSelectDay={handleDaySelect}
                onChangeWeek={handleWeekChange}
              />
              <AppointmentSlotList
                className="mb-5"
                slots={appointmentSlotMap?.[keyOfActiveDay] ?? []}
                loading={slotLoading}
                interactedSlots={interactedSlots}
                onSlotChange={handleSlotSelect}
              />
            </Fragment>
          )}

          {bookTime && (
            <BaseButton className="mb-2" fluid onClick={() => !!onBookClick && onBookClick(bookingData)}>
              {getBookButtonText()}
            </BaseButton>
          )}

          {!appointmentsAvailable ? (
            <span className="appointment-booking__empty-text text-center body">{t("No available appointments")}</span>
          ) : (
            <BaseButton className="appointment-booking__link-button link" fluid borderless onClick={onDescriptionClick}>
              {t("How it works?")}
            </BaseButton>
          )}
        </div>

        {askLink && (
          <BaseButton className="appointment-booking__ask-button mt-auto" to={askLink} outline>
            {t("Ask a question")}
          </BaseButton>
        )}
      </BaseCard>
    </Fragment>
  );
}

export default AppointmentBookingController;
