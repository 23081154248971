import React from "react";
import BaseSpinner from "../BaseSpinner";
import "./style.scss";

interface Props {
  loading: boolean;
  className?: string;
}

export default function ElementLoading({ loading, className }: Props) {
  const cls = ["element-loading d-center"];
  if (className) cls.push(className);
  if (!loading) return null;
  return (
    <div className={cls.join(" ")}>
      <BaseSpinner size={140} />
    </div>
  );
}
