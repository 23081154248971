import { getDate, getDay, getDayOfYear, sub } from "date-fns";
import { AppointmentSlotMap } from "../../types";
import { CalendarDay } from "./components/AppointmentBookingController/types";
import { DAYS_OF_WEEK_LIST, LAST_DAY_OF_WEEK, START_OF_WEEK } from "./consts";
import { RangeTime } from "./types";

export const startWeekDayDate = (): Date => {
  const presentDate = new Date();
  const presentDayOfWeek: number = getDay(presentDate);
  if (presentDayOfWeek > START_OF_WEEK) {
    return sub(presentDate, { days: Math.abs(presentDayOfWeek - START_OF_WEEK) });
  }
  if (presentDayOfWeek === 0 && START_OF_WEEK !== 0) {
    return sub(presentDate, { days: LAST_DAY_OF_WEEK });
  }
  return presentDate;
};

export const getEnabledStatus = (date: Date, map: AppointmentSlotMap): boolean => {
  return map[getDayOfYear(date)]?.length > 0 ?? false;
};

export const getCalendarDays = (activeDay: Date, dateList: Date[], map: AppointmentSlotMap): CalendarDay[] => {
  return dateList.map((d) => {
    return {
      date: d,
      dayOfMonth: getDate(d),
      enabled: getEnabledStatus(d, map),
      dayOfWeek: DAYS_OF_WEEK_LIST[getDay(d)],
      initialCheckStatus: getDayOfYear(activeDay) === getDayOfYear(d),
    };
  });
};

export const getRangeTime = (days: CalendarDay[]): RangeTime => ({
  startTime: days[0].date,
  endTime: days[LAST_DAY_OF_WEEK].date,
});
