import React from "react";
import { Link } from "react-router-dom";
import { Appointment } from "@scrile/api-provider/dist/api/AppointmentProvider";
import { QuickTip } from "../../../../../../types";
import providers from "../../../../../../lib/providers";
import { useTranslation } from "../../../../../../locales";
import ModalWindow from "../../../../../../components/ModalWindow";
import BaseButton from "../../../../../../components/BaseButton";
import BaseIcon from "../../../../../../components/BaseIcon";
import BaseMenu from "../../../../../../components/BaseMenu";
import QuickTipsItem from "../../../../../../components/QuickTipsItem";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  appointment: Appointment;
  isAlmostMeetingStart: boolean;
  isCoach?: boolean;
  onCancelSuccess: () => void;
  hideStatus?: boolean;
}

export default function AppointmentItem({
  appointment,
  isAlmostMeetingStart,
  isCoach = true,
  onCancelSuccess,
  hideStatus,
}: Props) {
  const { t } = useTranslation();
  const {
    user,
    date,
    time,
    min,
    optionsList,
    show,
    setShow,
    cancelled,
    setCancelled,
    processing,
    setProcessing,
    duration,
    isLate,
    refundWarning,
    appointmentStatus,
  } = useViewController(appointment, isCoach);

  const quickTip: QuickTip = {
    translateKey: t("Please make sure you cancel both on SecureTheOffer and on Google Calendar."),
    icon: "info",
  };

  const isMeetingRange = () => {
    const startMs = new Date(appointment.startTime).getTime() - 60000 * 5;
    const endMs = new Date(appointment.endTime).getTime();
    const nowTime = Date.now();
    return nowTime >= startMs && nowTime <= endMs;
  };
  const showCameraLink = isMeetingRange() || isAlmostMeetingStart;

  const onClickItem = (itemName: string) => {
    if (itemName === "cancel") {
      setShow(true);
    }
  };

  const onConfirmClick = async () => {
    try {
      setProcessing(true);
      const response = await providers.AppointmentProvider.cancel({ data: { id: appointment.id, message: "" } });
      if (response.status === "CANCELLED") {
        setCancelled(true);
      }
    } finally {
      setProcessing(false);
    }
  };

  const onModalClose = () => {
    if (cancelled) {
      onCancelSuccess();
    }
    setShow(false);
    setCancelled(false);
  };

  return (
    <div className="appointments-item label">
      <div className="appointments-item__date fw-semibold">
        <span>
          {date.dayOfWeek} {date.dayOfMonth},{" "}
        </span>
        <span className="appointments-item__date-month">
          {date.month} {date.year}
        </span>
      </div>
      {optionsList.length > 0 && !showCameraLink && (
        <div className="appointments-item__more">
          <BaseMenu className="appointments-item__menu" itemsList={optionsList} onClick={onClickItem}>
            <BaseButton name="appointment-options" icon opacity onClick={() => {}}>
              <BaseIcon size={28}>more</BaseIcon>
            </BaseButton>
          </BaseMenu>
        </div>
      )}
      <div className="appointments-item__time fw-semibold">{`${time}, ${min} ${t("min")}`}</div>
      <div className="appointments-item__name">
        {isCoach ? (
          user.screenName
        ) : (
          <Link to={`/${user.username}`} className="d-inline">
            {user.screenName}
          </Link>
        )}
      </div>
      {!hideStatus && <div className="appointments-item__status fw-semibold">{appointmentStatus}</div>}
      {showCameraLink && (
        <BaseButton
          className="appointments-item__join-btn"
          dataTestId="join-to-meeting"
          to={`/appointment/${appointment.id}`}
          live
        >
          <BaseIcon className="appointments-item__join-btn-icon">camera</BaseIcon>
        </BaseButton>
      )}
      <ModalWindow show={show} onClose={onModalClose}>
        {cancelled ? (
          <div className="appointments-item__modal-content">
            <div className="appointments-item__modal-icon __success mx-auto d-center">
              <BaseIcon size={40}>done</BaseIcon>
            </div>
            <div className="headline-1 mb-4">{t("Appointment cancelled")}</div>
            <div className="body">
              {t("You have cancelled the appointment with {{screenName}}. {{moneyText}}", {
                screenName: user?.screenName || "",
                moneyText: !isCoach && !isLate ? t("The money are refunded to your balance.") : "",
              })}
            </div>
            {!isCoach && (
              <BaseButton className="appointments-item__modal-button mt-6" to="/coaches">
                {t("Book another")}
              </BaseButton>
            )}
          </div>
        ) : (
          <div className="appointments-item__modal-content">
            <div className="appointments-item__modal-icon mx-auto d-center">
              <BaseIcon>appointment-cancel</BaseIcon>
            </div>
            <div className="headline-1 mb-4">{t("Appointment cancellation")}</div>
            <div className="body mb-4">
              {t("Are you sure you want to cancel the appointment with {{screenName}}? {{moneyText}}", {
                screenName: user?.screenName || "",
                moneyText: isCoach
                  ? t("The session credit will be refunded to the user.")
                  : isLate
                  ? refundWarning
                  : t("The session credit will be refunded to your balance."),
              })}
            </div>
            {isCoach && <QuickTipsItem className="mb-6" item={quickTip} />}
            <div className="headline-2 mb-1">
              {date.month} {date.dayOfMonth}, {date.year} {time}
            </div>
            <div className="headline-2 mb-5">
              {t("{{duration}} / {{price}} DC", { duration, price: appointment.amount })}
            </div>
            <BaseButton
              className="appointments-item__modal-button"
              onClick={onConfirmClick}
              loading={processing}
              dataTestId="cancel-appointment-confirm"
            >
              {t("Cancel the appointment")}
            </BaseButton>
          </div>
        )}
      </ModalWindow>
    </div>
  );
}
