import React from "react";
import { Transaction } from "@scrile/api-provider/dist/api/TransactionProvider";
import { useTranslation } from "../../../../locales";
import { getTransactionItem } from "../Transaction";
import "./style.scss";

interface Props {
  transactions: Transaction[];
}

export default function TransactionList({ transactions }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="transaction-list__heading">
        <div className="transaction-list__heading-type">{t("Type")}</div>
        <div className="transaction-list__heading-date">{t("Date")}</div>
        <div className="transaction-list__heading-amount">{t("Amount")}</div>
      </div>
      {transactions.map((t) => {
        const TransactionItem = getTransactionItem(t);
        return <TransactionItem key={t.id} transaction={t} />;
      })}
    </>
  );
}
