import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import useAuthUser from "../../../../hooks/useAuthUser";
import useAppointments from "../../../../hooks/useAppointments";
import useAppointmentReminder from "../../../../hooks/useAppointmentReminder";
import { isPerformer } from "../../../../lib/usersHelper";
import { NavigationMenuItemInterface } from "../../../../types";
import { t } from "../../../../locales";
import BaseCard from "../../../../components/BaseCard";
import AppointmentsRange from "./components/AppointmentsRange";
import NavigationTabs from "../NavigationTabs";
import "./style.scss";

const AppointmentsPage = () => {
  const match = useRouteMatch();
  const { user } = useAuthUser();
  const { processing, availability } = useAppointments();

  const isCoach = isPerformer(user);

  const { appointment: almostStartedAppointment } = useAppointmentReminder(true);

  const topNavigationMenu: NavigationMenuItemInterface[] = [
    {
      title: t("Upcoming"),
      name: "upcoming",
      to: "/user/appointments",
    },
    {
      title: t("Past"),
      name: "past",
      to: "/user/appointments/past",
    },
  ];

  return (
    <BaseCard className="appointments-page">
      <NavigationTabs itemsList={topNavigationMenu} />
      <div className="appointments-page__wrapper">
        {!processing && (
          <Switch>
            <Route exact path={`${match.url}`}>
              <AppointmentsRange
                hideStatus
                endTimeFrom={new Date().toISOString()}
                almostStartedAppointmentId={almostStartedAppointment?.id}
              />
            </Route>
            <Route path={`${match.url}/past`}>
              <AppointmentsRange endTimeTo={new Date().toISOString()} sort="START_TIME,DESC" />
            </Route>
            <Route path={`*`}>
              <Redirect to={isCoach && !availability ? "/user/calendar/settings" : `${match.url}`} />
            </Route>
          </Switch>
        )}
      </div>
    </BaseCard>
  );
};

export default AppointmentsPage;
