import React, { useEffect, useRef, useState } from "react";
import { AppointmentUserData } from "../../../../types";
import CustomScrollBar from "../../../../components/CustomScrollBar";
import MainStreamView from "../MainStreamView";
import ScreenStreamItem from "../ScreenStreamItem";
import SecondStreamView from "../SecondStreamView";
import UserStreamItem from "../UserStreamItem";
import "./style.scss";

interface Props {
  produceData: AppointmentUserData;
  consumeData: AppointmentUserData;
  setPlayer: React.Dispatch<React.SetStateAction<HTMLVideoElement | null>>;
}

export default function AppointmentStreamView({ setPlayer, consumeData, produceData }: Props) {
  const [focusedData, setFocusedData] = useState<AppointmentUserData | null>(null);
  const prevState = useRef({
    produce: produceData.hasScreenTracks,
    consume: consumeData.hasScreenTracks,
  });

  useEffect(() => {
    const produceHasScreen = produceData.hasScreenTracks;
    const consumeHasScreen = consumeData.hasScreenTracks;

    const focusedOnProduce = prevState.current.produce;
    const focusedOnConsume = prevState.current.consume;

    const focusedProduceMatch = focusedData?.screenStream.id === produceData.screenStream.id;
    const focusedConsumeMatch = focusedData?.screenStream.id === consumeData.screenStream.id;

    if (produceHasScreen && (!focusedOnProduce || !consumeHasScreen)) {
      setFocusedData(produceData);
    } else if (consumeHasScreen && (!focusedOnConsume || !produceHasScreen)) {
      setFocusedData(consumeData);
    } else if (!produceHasScreen && !consumeHasScreen) {
      setFocusedData(null);
    } else if (produceHasScreen && focusedProduceMatch) {
      setFocusedData(produceData);
    } else if (consumeHasScreen && focusedConsumeMatch) {
      setFocusedData(consumeData);
    }

    prevState.current = {
      produce: produceHasScreen,
      consume: consumeHasScreen,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumeData, produceData]);

  const revertProduceVideo =
    !!produceData.constraints &&
    typeof produceData.constraints.video === "object" &&
    typeof produceData.constraints.video.facingMode === "string" &&
    produceData.constraints.video.facingMode === "environment";

  return (
    <div className="appointment-stream-view">
      {!consumeData.hasScreenTracks && !produceData.hasScreenTracks ? (
        <>
          <MainStreamView
            className="appointment-stream-view__main-stream"
            user={consumeData.user}
            stream={consumeData.stream}
            constraints={consumeData.constraints}
            setPlayer={setPlayer}
          />

          {!!produceData.constraints?.videoEnabled && (
            <SecondStreamView stream={produceData.stream} hasVideo mirrorReflection={revertProduceVideo} />
          )}
        </>
      ) : (
        <>
          <CustomScrollBar className="appointment-stream-view__users-wrapper" style={{ maxHeight: "130px" }}>
            <div className="appointment-stream-view__users pa-3">
              {produceData.hasScreenTracks && (
                <UserStreamItem
                  className="appointment-stream-view__screen-stream __produce"
                  user={produceData.user}
                  stream={produceData.screenStream}
                  hasVideo={!!produceData.constraints?.shareEnabled}
                  hide={produceData === focusedData}
                  onClick={() => setFocusedData(produceData)}
                />
              )}
              {consumeData.hasScreenTracks && (
                <UserStreamItem
                  className="appointment-stream-view__screen-stream __consume"
                  user={consumeData.user}
                  stream={consumeData.screenStream}
                  hasVideo={!!consumeData.constraints?.shareEnabled}
                  hide={consumeData === focusedData}
                  onClick={() => setFocusedData(consumeData)}
                />
              )}
              <UserStreamItem
                className="appointment-stream-view__user-stream"
                user={produceData.user}
                stream={produceData.stream}
                hasVideo={!!produceData.constraints?.videoEnabled}
                hasAudio={!!produceData.constraints?.audioEnabled}
                mirrorReflection={revertProduceVideo}
              />
              <UserStreamItem
                className="appointment-stream-view__user-stream"
                user={consumeData.user}
                stream={consumeData.stream}
                hasVideo={!!produceData.constraints?.videoEnabled}
                hasAudio={!!produceData.constraints?.audioEnabled}
                setPlayer={setPlayer}
              />
            </div>
          </CustomScrollBar>
          {focusedData && (
            <ScreenStreamItem
              className="appointment-stream-view__focused-stream"
              user={focusedData.user}
              stream={focusedData.screenStream}
              hasVideo={!!focusedData.constraints?.shareEnabled}
            />
          )}
        </>
      )}
    </div>
  );
}
