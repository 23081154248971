import { useMemo, useState } from "react";
import { differenceInHours, isAfter } from "date-fns";
import { Appointment, AppointmentStatus } from "@scrile/api-provider/dist/api/AppointmentProvider";
import { differenceInMilliseconds } from "@scrile/tools/dist/lib/TimeHelpers";
import { get12formatTime, getDate, getDuration } from "../../../../../../lib/timeHelper";
import { BaseMenuItemInterface } from "../../../../../../types";
import { useTranslation } from "../../../../../../locales";

export default function useViewController(appointment: Appointment, isCoach: boolean) {
  const REFUND_TIME_HOURS = 24;
  const REFUND_TIME_DAYS = REFUND_TIME_HOURS / 24;

  const { t } = useTranslation();
  const user = useMemo(() => (isCoach ? appointment.consumer : appointment.provider), [isCoach, appointment]);
  const [show, setShow] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [processing, setProcessing] = useState(false);
  const date = getDate(appointment.startTime);
  const isLate = useMemo(() => differenceInHours(new Date(appointment.startTime), new Date()) < REFUND_TIME_HOURS, [
    appointment,
  ]);
  const appointmentStatus = useMemo(() => {
    switch (appointment.status) {
      case AppointmentStatus.COMPLETED:
        return t("Completed");
      case AppointmentStatus.CANCELLED:
        return t("Cancelled");
      case AppointmentStatus.NOSHOW:
        return t("Cancelled");
    }
  }, [appointment, t]);
  const refundWarning = t(
      "The session credit won’t be refunded to your balance since you are canceling less than {{dayCount}} {{dayPluralization}} before.",
      {
        dayCount: REFUND_TIME_HOURS > 24 ? REFUND_TIME_DAYS : REFUND_TIME_HOURS,
        dayPluralization: REFUND_TIME_HOURS <= 24 ? t("hours") : REFUND_TIME_DAYS === 1 ? t("day") : t("days"),
      }
  )

  const time = get12formatTime(appointment.startTime);
  const min =
    differenceInMilliseconds(new Date(appointment.endTime), new Date(appointment?.startTime || "")) / 1000 / 60;
  const duration = getDuration(min);
  const optionsList = useMemo<BaseMenuItemInterface[]>(() => {
    return [
      ...(["CANCELLED", "REJECTED"].includes(appointment.status) || isAfter(new Date(), new Date(appointment.startTime))
        ? []
        : [
            {
              name: "cancel",
              title: t("Cancel appointment"),
              icon: "close",
            },
          ]),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment, isCoach]);

  return {
    user,
    date,
    time,
    min,
    optionsList,
    show,
    setShow,
    cancelled,
    setCancelled,
    processing,
    setProcessing,
    duration,
    isLate,
    refundWarning,
    appointmentStatus,
  };
}
