import { useState } from "react";
import { useMount } from "react-use";
import { UserAvailability, UserAvailabilityInterval } from "@scrile/api-provider/dist/api/UserAvailabilityProvider";
import providers from "../../../../../../lib/providers";
import useAuthUser from "../../../../../../hooks/useAuthUser";

export default function useController() {
  const { user } = useAuthUser();
  const [userAvailability, setUserAvailability] = useState<UserAvailability>({
    userId: user?.id ?? "0",
    intervals: [],
  });
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  useMount(() => {
    (async function () {
      setLoading(true);
      try {
        if (!user?.id) return;
        const result = await providers.UserAvailabilityProvider.search({
          data: { userIds: [user.id] },
        })
        const foundUserAvailability = result.find((i) => i.userId === user.id);
        if (foundUserAvailability) setUserAvailability(foundUserAvailability);
      } finally {
        setLoading(false);
      }
    })();
  });

  const saveUserAvailability = async (intervals: UserAvailabilityInterval[]) => {
    setProcessing(true);
    try {
      if (!user?.id) return;
      await providers.UserAvailabilityProvider.update({
        data: {
          userId: user.id,
          intervals,
        },
      });
    } finally {
      setProcessing(false);
    }
  };

  return { loading, processing, userAvailability, saveUserAvailability };
}
