import React from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { Subject } from "@scrile/api-provider/dist/api/SubjectProvider";
import { LivechatSubjectType } from "@scrile/api-provider/dist/api/LivechatsProvider";
import PublicStreamView from "../PublicStreamView";
import useConsumeController from "../../../../hooks/useConsumeController";

interface Props {
  produceUser: UserPublicData;
  onClose: () => void;
  livechatSubject: Subject<LivechatSubjectType>;
  disableAttachments?: boolean;
}

function ConsumeController({ produceUser, livechatSubject, onClose, disableAttachments }: Props) {
  const {
    stream,
    threadId,
    loading,
    userList,
    streamStartedDate,
    token,
    mediaConstraints,
  } = useConsumeController(produceUser.id, livechatSubject);

  return (
    <PublicStreamView
      produceUser={produceUser}
      isProducerUser={false}
      consumeUser={null}
      threadId={threadId}
      produce={false}
      consume={true}
      stream={stream}
      loading={loading}
      userList={userList}
      streamStartedDate={streamStartedDate}
      onClose={onClose}
      token={token}
      constraints={mediaConstraints}
      managedConstraints={mediaConstraints}
      disableAttachments={disableAttachments}
      showConfirmationExit={false}
    />
  );
}
export default ConsumeController;
