import React from "react";
import { useTranslation } from "../../../../locales";
import BaseButton from "../../../BaseButton";
import BaseIcon from "../../..//BaseIcon";
import ModalWindow from "../../..//ModalWindow";

interface Props {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  slotContent?: JSX.Element;
}

function ModalStreamConfirmationExit({ show, onCancel, onConfirm, slotContent }: Props) {
  const { t } = useTranslation();

  return (
    <ModalWindow show={show} onClose={onCancel} className="stream-view__confirm-exit">
      <>
        {slotContent ? slotContent : <h1 className="headline-1 text-center">{t("Do you want to end the chat?")}</h1>}
        <div className="stream-view__confirm-exit-buttons">
          <BaseButton large outline onClick={onCancel} className="stream-view__confirm-exit-btn">
            {t("Cancel")}
          </BaseButton>
          <BaseButton name="confirm-exit" large negative onClick={onConfirm} className="stream-view__confirm-exit-btn">
            <BaseIcon className="stream-view__control-icon mr-1">call</BaseIcon>
            {t("End Now")}
          </BaseButton>
        </div>
      </>
    </ModalWindow>
  );
}

export default ModalStreamConfirmationExit;
