import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isPerformer } from "../../lib/usersHelper";
import useAppointmentReminder from "../../hooks/useAppointmentReminder";
import useAuthUser from "../../hooks/useAuthUser";

export default function useViewController() {
  const { user } = useAuthUser();
  const { appointment } = useAppointmentReminder();
  const location = useLocation();
  const history = useHistory();

  const [show, setShow] = useState(false);
  const [forceHide, setForceHide] = useState(false);
  const [startTime, setStartTime] = useState("");

  const meetingPartner = isPerformer(user) ? appointment?.consumer : appointment?.provider;
  const streamRoutePath = `/appointment/${appointment?.id}`;

  const onJoinChatClick = () => history.push(streamRoutePath);
  const onClose = () => setForceHide(true);

  useEffect(() => {
    if (!appointment) {
      setForceHide(false);
      setStartTime("");
    }
    if (user && appointment && !forceHide && !location.pathname.includes(streamRoutePath)) {
      setShow(true);
      setStartTime(appointment.startTime);
      return;
    }
    setShow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, appointment, location.pathname, forceHide]);

  return {
    show,
    startTime,
    meetingPartner,
    appointmentId: appointment?.id,
    onClose,
    onJoinChatClick,
  };
}
