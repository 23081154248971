import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { AppointmentType, AppointmentTypeKind } from "@scrile/api-provider/dist/api/AppointmentTypeProvider";
import { AppointmentSlot } from "@scrile/api-provider/dist/api/AppointmentSlotProvider";
import { useTranslation } from "../../locales";
import useViewController from "./hooks/viewController";
import useController from "./hooks/controller";
import ModalWindow from "../../components/ModalWindow";
import BaseIcon from "../../components/BaseIcon";
import BaseButton from "../../components/BaseButton";
import "./style.scss";

interface Props {
  show: boolean;
  amount: number;
  user: UserPublicData;
  startTime?: AppointmentSlot["startTime"];
  appointmentType: AppointmentType | null;
  onClose: () => void;
  onAppointmentPaidSuccess?: () => void;
}

export default function PaymentBooking({
  show,
  user,
  amount,
  startTime,
  appointmentType,
  onClose,
  onAppointmentPaidSuccess,
}: Props) {
  const [isBooked, setIsBooked] = useState<boolean>(false);
  const [isNotEnoughBalance, setIsNotEnoughBalance] = useState<boolean>(false);

  const { t } = useTranslation();
  const history = useHistory();

  const { balance, loading, onRefillBalance, payForAppointment } = useController();
  const { price, title, duration, startDate, statusText, buttonText } = useViewController(
    amount,
    user.screenName,
    isBooked,
    isNotEnoughBalance,
    appointmentType,
    startTime
  );

  const handleButtonClick = async () => {
    if (!startTime) return;
    if (isBooked) return history.push("/user/appointments");
    if (isNotEnoughBalance) return onRefillBalance();
    if (amount > balance) return setIsNotEnoughBalance(true);
    if (appointmentType?.kind !== AppointmentTypeKind.LIVE) throw Error("An appointment type is not supported");

    const appointment = await payForAppointment({
      typeId: appointmentType?.id ?? "0",
      startTime: new Date(startTime).toISOString(),
    });
    if (appointment) {
      setIsBooked(true);
      !!onAppointmentPaidSuccess && onAppointmentPaidSuccess();
    }
  };

  useEffect(() => {
    if (balance >= amount) setIsNotEnoughBalance(false);
  }, [balance, amount]);

  return (
    <ModalWindow className="payment-booking" show={show} onClose={onClose}>
      <div className="payment-booking__content">
        <div className="payment-booking__icon mb-4">
          <BaseIcon size={32}>booking_confirmed</BaseIcon>
        </div>
        <h2 className="payment-booking__heading mb-4">{title}</h2>
        <span className="payment-booking__status-text mb-6">{statusText}</span>
        <span className="payment-booking__date mb-3">{startDate}</span>
        <span className="payment-booking__duration-price mb-6">{`${duration} / ${price}`}</span>
        {isNotEnoughBalance && (
          <span className="payment-booking__balance-hint mb-3">
            {t("Insufficient funds to pay for the appointment")}
          </span>
        )}
        <BaseButton outline={isBooked} loading={loading} fluid onClick={handleButtonClick}>
          {buttonText}
        </BaseButton>
      </div>
    </ModalWindow>
  );
}
