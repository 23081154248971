import React, { useState } from "react";
import { DefaultStreamViewProps } from "../../../../types";
import { useTranslation } from "../../../../locales";
import StreamView from "../../../../components/StreamView";
import ChatTimer from "../../../../components/ChatTimer";
import useAuthUser from "../../../../hooks/useAuthUser";
import ModalWindow from "../../../../components/ModalWindow";
import LiveChatShare from "../../../../components/LiveChatShare";
import BaseButton from "../../../../components/BaseButton";
import BaseIcon from "../../../../components/BaseIcon";
import useViewController from "./viewController";
import "./style.scss";

export default function PublicStreamView(props: DefaultStreamViewProps) {
  const { user: authUser } = useAuthUser();
  const [showShare, setShowShare] = useState(false);
  const { localStream, stopLocalTracks } = useViewController(props.stream);
  const { t } = useTranslation();

  const onClose = () => {
    stopLocalTracks();
    props.onClose();
  };

  return (
    <>
      {authUser && props.produceUser && (
        <ModalWindow show={showShare} onClose={() => setShowShare(false)} className="public-stream-view__share-modal">
          <LiveChatShare produce={props.produce} produceUser={props.produceUser} />
        </ModalWindow>
      )}

      <StreamView
        {...props}
        stream={localStream}
        onClose={onClose}
        slotTimer={
          <ChatTimer showTimeIfNegative streamStartedDate={props.streamStartedDate}>
            {"Live chat"}
          </ChatTimer>
        }
        slotHeaderButtons={
          authUser ? (
            <BaseButton className="public-stream-view__btn __share" name={"share"} onClick={() => setShowShare(true)}>
              <BaseIcon className="public-stream-view__btn-icon">share</BaseIcon>
            </BaseButton>
          ) : undefined
        }
        slotContentConfirmationExit={
          <h1 className="headline-1 text-center">{t("Do you want to end the live chat?")}</h1>
        }
      />
    </>
  );
}
