import React from "react";
import { useMount } from "react-use";
import { AppointmentSlot } from "@scrile/api-provider/dist/api/AppointmentSlotProvider";
import { formatDate } from "../../../../../../lib/timeHelper";
import "./style.scss";

type Props = {
  className?: string;
  slot: AppointmentSlot;
  labelColorClass?: string;
  onClick: (slot: AppointmentSlot | null) => void;
};

export default function AppointmentSlotView({ className, slot, labelColorClass, onClick }: Props) {
  const cls = ["appointment-slot"];
  if (className) cls.push(className);

  const id = "slot-" + slot.startTime;
  const getSlotTime = (date: string): string => formatDate(new Date(date), { hour: "2-digit", minute: "2-digit" });

  useMount(() => !!onClick && onClick(null));

  return (
    <div className={cls.join(" ")}>
      <input
        className="appointment-slot__radio"
        id={id}
        type="radio"
        name="appointmentDate"
        onClick={() => onClick(slot)}
      />
      <label className={"appointment-slot__label noselect " + (labelColorClass ? labelColorClass : "")} htmlFor={id}>
        {getSlotTime(slot.startTime)}
      </label>
    </div>
  );
}
