import { useEffect, useState } from "react";
import { Appointment, AppointmentStatus } from "@scrile/api-provider/dist/api/AppointmentProvider";
import { add } from "date-fns";
import providers from "../lib/providers";
import { isPerformer } from "../lib/usersHelper";
import emitter, { EVENTS } from "../lib/emitter";
import useAuthUser from "./useAuthUser";
import useCallbackDelay from "./useCallbackDelay";

const NOTIFY_MINUTES_TIME_IN = 5;

const useAppointmentReminder = (onlySubscribe = false) => {
  const { user } = useAuthUser();

  const [appointment, setAppointment] = useState<Appointment | null>(null);

  useCallbackDelay(() => setAppointment(null), appointment ? new Date(appointment.endTime).getTime() : 0);

  useEffect(() => {
    if (!user || onlySubscribe) return;
    const nowDate = new Date().toISOString();
    providers.AppointmentProvider.search({
      providerId: isPerformer(user) ? user.id : undefined,
      consumerId: !isPerformer(user) ? user.id : undefined,
      sort: "START_TIME,DESC",
      status: [
        AppointmentStatus.NEW,
        AppointmentStatus.APPROVED,
        AppointmentStatus.COMPLETED,
        AppointmentStatus.NOSHOW,
      ],
      startTimeTo: add(new Date(), { minutes: NOTIFY_MINUTES_TIME_IN }).toISOString(),
      endTimeFrom: nowDate,
    }).then((response) => {
      const firstAppointment = response.result.length > 0 ? response.result[0] : null;
      if (firstAppointment && firstAppointment.status === "APPROVED") {
        setAppointment(firstAppointment);
      }
    });
  }, [user, onlySubscribe]);

  useEffect(() => {
    if (!user) return;
    const handleAppointmentRemind = (data?: Appointment): void => {
      if (!!data && data.id !== appointment?.id) setAppointment(data);
    };

    emitter.on<Appointment>(EVENTS.APPOINTMENT_REMINDER, handleAppointmentRemind);
    return () => {
      emitter.off<Appointment>(EVENTS.APPOINTMENT_REMINDER, handleAppointmentRemind);
    };
  }, [user, appointment]);

  return {
    appointment,
  };
};

export default useAppointmentReminder;
