import { useEffect } from "react";
import { differenceInMilliseconds } from "@scrile/tools/dist/lib/TimeHelpers";

const useCallbackDelay = (callback: () => void, delayInMs: number = 0): void => {
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;
    if (delayInMs <= 0) return;

    !!timeoutId && clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      !!timeoutId && clearInterval(timeoutId);
      callback();
    }, differenceInMilliseconds(new Date(delayInMs), new Date()));

    return () => {
      !!timeoutId && clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayInMs]);
};

export default useCallbackDelay;
