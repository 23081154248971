import { useMemo } from "react";

export default function useTimezone() {
  const timezoneOffset = new Date().getTimezoneOffset() * -1;
  const displayTimezone = useMemo(() => {
    return `${Intl.DateTimeFormat().resolvedOptions().timeZone.split("/")[1]}
     (GMT${timezoneOffset >= 0 ? "+" : "-"}${("0" + Math.floor(Math.abs(timezoneOffset) / 60)).slice(-2)}:${(
      "0" +
      (Math.abs(timezoneOffset) % 60)
    ).slice(-2)})`;
  }, [timezoneOffset]);

  return {
    timezoneOffset,
    displayTimezone,
  };
}
