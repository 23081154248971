import { useState } from "react";
import useBreakPoints from "../../hooks/useBreakPoints";
import useController from "./controller";

export default function useViewController(username: string) {
  const [showMobileBook, setShowMobileBook] = useState<boolean>(false);
  const [mobileReviewsMode, setMobileReviewsMode] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
  const { tabletUp: isTablet } = useBreakPoints();
  const {
    user,
    video,
    videoLoading,
    reviewsLoading,
    onReviewAdded,
    reviewItems,
    getNextReviewsPage,
    enableReviewsScroll,
    totalReviewsRecords,
    canBookAppointment,
  } = useController(username);

  return {
    user,
    showMobileBook,
    setShowMobileBook,
    video,
    videoLoading,
    showVideoModal,
    setShowVideoModal,
    reviewsLoading,
    mobileReviewsMode,
    setMobileReviewsMode,
    isTablet,
    onReviewAdded,
    reviewItems,
    getNextReviewsPage,
    enableReviewsScroll,
    totalReviewsRecords,
    canBookAppointment,
  };
}
