import { format, getDurationSymbols, parseISO } from "@scrile/tools/dist/lib/TimeHelpers";
import { DEFAULT_LOCALE } from "../const";
import { add } from "date-fns";

export function get12formatTime(time: string) {
  return format(parseISO(time), "h:mm aaa");
}

export function get24formatTime(time: string) {
  return format(parseISO(time), "H:mm");
}

export function getDate(time: string) {
  const [dayOfWeek, dayOfMonth, month, year] = format(parseISO(time), "eee,dd,MMM,yyyy").split(",");

  return {
    dayOfWeek,
    dayOfMonth,
    month,
    year,
  };
}

export function formatDate(date: Date, options?: Partial<Intl.DateTimeFormatOptions>): string {
  return date.toLocaleString(DEFAULT_LOCALE, options);
}

export const formatAppointmentDate = (day: Date = new Date()): string => {
  return formatDate(day, { day: "numeric", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" });
};

export const getCalendarTitle = (firsOfWeekDay: Date): string => {
  const presentMonth = formatDate(firsOfWeekDay, { month: "short" });
  const nextMonthAndYear = formatDate(add(firsOfWeekDay, { months: 1 }), { month: "short", year: "numeric" });
  return `${presentMonth} - ${nextMonthAndYear}`;
};

export const getDuration = (minutes: number): string => {
  if (minutes < 60) return `${minutes} min`;

  const ms = minutes * 60 * 1000;
  const durationSymbols = getDurationSymbols(ms);
  const h = `${durationSymbols.h} h`;
  const m = durationSymbols.m === 0 ? "" : `${durationSymbols.m} mins`;

  return `${h} ${m}`;
};
