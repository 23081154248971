import React, { useMemo, useState } from "react";
import { debounce } from "lodash-es";
import BaseInput from "../../../../components/BaseInput";
import { useTranslation } from "../../../../locales";
import "./style.scss";

interface Props {
  className?: string;
  debounceTime?: number;
  onChange: (v: string) => void;
}

function FilterByName({ className, debounceTime = 500, onChange }: Props) {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const debouncedOnChange = useMemo(() => debounce(onChange, debounceTime), [debounceTime, onChange]);

  const onInput = (v: string) => {
    setValue(v);
    debouncedOnChange(v);
  };

  const cls = ["filter-by-name"];
  className && cls.push(className);

  return (
    <BaseInput
      className={cls.join(" ")}
      value={value}
      name="filterName"
      iconLeft="search"
      iconRight={value ? "close" : ""}
      iconRightClassName="filter-by-name__icon-right"
      onClickIconRight={() => onInput("")}
      type="text"
      onChange={onInput}
      placeholder={t("Search by name")}
      blurBy={"Enter"}
    />
  );
}

export default FilterByName;
