import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { useTranslation } from "../../locales";
import ContentHeader from "../../components/ContentHeader";
import PageSettingsChangePassword from "../PageSettingsChangePassword";
import PageBalanceHistory from "../PageBalanceHistory";
import NavigationMenu from "./components/NavigationMenu";
import NavigationMenuMobile from "./components/NavigationMenuMobile";
import AppointmentsPage from "./components/AppointmentsPage";
import MainInfoProfile from "./components/MainInfoProfile";
import PaymentInfo from "./components/PaymentInfo";
import CalendarPage from "./components/CalendarPage";
import BasePage from "../../components/BasePage";
import useViewController from "./viewController";
import "./style.scss";

export default function PageUserArea() {
  const match = useRouteMatch();
  const { user, itemsList, isPerformer } = useViewController();
  const { t } = useTranslation();

  if (!user) {
    return <Redirect to="/coaches" />;
  }
  const side = (
    <>
      <NavigationMenuMobile itemsList={itemsList} exact={false} />
      <NavigationMenu itemsList={itemsList} />
    </>
  );
  return (
    <BasePage
      className="page-user-area"
      side={side}
      prepend={<ContentHeader className="page-user-area__header">{t("Settings")}</ContentHeader>}
    >
      <Switch>
        <Route exact={true} path={`${match.url}`}>
          <Redirect to={itemsList[0].to} />
        </Route>
        <Route path={`${match.url}/settings`}>
          <MainInfoProfile />
        </Route>
        {isPerformer && (
          <Route path={`${match.url}/payment-info`}>
            <PaymentInfo />
          </Route>
        )}
        <Route path={`${match.url}/appointments`}>
          <AppointmentsPage />
        </Route>
        {isPerformer && (<Route path={`${match.url}/calendar`}>
          <CalendarPage />
        </Route>)}
        <Route path={`${match.url}/transactions`}>
          <PageBalanceHistory />
        </Route>
        <Route path={`${match.url}/change-password`}>
          <PageSettingsChangePassword />
        </Route>
        <Route>
          <Redirect to={itemsList[0].to} />
        </Route>
      </Switch>
    </BasePage>
  );
}
