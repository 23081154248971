import React from "react";
import { debounce } from "lodash-es";
import { Appointment } from "@scrile/api-provider/dist/api/AppointmentProvider";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { isMobile } from "@scrile/tools/dist/lib/browserUtils";
import { Subject } from "@scrile/api-provider/dist/api/SubjectProvider";
import { LivechatSubjectType } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { AppointmentUserData } from "../../../../types";
import VideoControlButton from "../../../../components/StreamView/components/VideoControlButton";
import BaseIcon from "../../../../components/BaseIcon";
import AppointmentView from "../AppointmentView";
import useProduceConsumeController from "../../../../hooks/useProduceConsumeController";

interface Props {
  owner: boolean;
  produceUser: UserPublicData;
  consumeUser: UserPublicData;
  onClose: () => void;
  livechatSubject: Subject<LivechatSubjectType>;
  appointment?: Appointment;
  disableAttachments?: boolean;
}

function AppointmentStreamController({
  produceUser,
  consumeUser,
  livechatSubject,
  onClose,
  appointment,
  owner,
}: Props) {
  const {
    threadId,
    produceStream,
    produceScreenStream,
    consumeStream,
    consumeScreenStream,
    loading,
    users,
    token,
    consumeMediaConstraints,
    produceMediaConstraints,
    audioInputs,
    videoInputs,
    isShare,
    hasConsumeTracks,
    hasConsumeScreenTracks,
    hasProduceTracks,
    hasProduceScreenTracks,
    isProducerFinishedStream,
    onCloseStream,
    onChangeConstraints,
    onProducerFinishedStream,
  } = useProduceConsumeController(owner ? consumeUser.id : produceUser.id, livechatSubject, true);

  const produceData: AppointmentUserData = {
    user: owner ? produceUser : consumeUser,
    stream: produceStream,
    screenStream: produceScreenStream,
    hasTracks: hasProduceTracks,
    hasScreenTracks: hasProduceScreenTracks,
    constraints: produceMediaConstraints,
  };

  const consumeData: AppointmentUserData = {
    user: owner ? consumeUser : produceUser,
    stream: consumeStream,
    screenStream: consumeScreenStream,
    hasTracks: hasConsumeTracks,
    hasScreenTracks: hasConsumeScreenTracks,
    constraints: consumeMediaConstraints,
  };

  const onToggleScreenSharing = debounce(async () => {
    if (!onChangeConstraints || !produceMediaConstraints) return;
    await onChangeConstraints({ shareEnabled: !produceMediaConstraints.shareEnabled });
  }, 200);

  const controlButton = !isMobile() ? (
    <VideoControlButton
      className="panel-control-buttons__btn"
      name="toggle-screen"
      toggle={isShare}
      borderless
      onClick={onToggleScreenSharing}
      disabled={loading}
    >
      <BaseIcon>{!isShare ? "screen-share_on" : "screen-share_off"}</BaseIcon>
    </VideoControlButton>
  ) : undefined;

  return (
    <AppointmentView
      loading={loading}
      token={token}
      threadId={threadId}
      userList={users}
      produceData={produceData}
      consumeData={consumeData}
      isProducerUser={owner}
      isProducerFinishedStream={isProducerFinishedStream}
      streamStartedDate={appointment?.startTime ?? ""}
      streamEndedDate={appointment?.endTime ?? ""}
      audioInputs={audioInputs}
      videoInputs={videoInputs}
      onClose={onClose}
      onCloseStream={onCloseStream}
      onProduceFinishedStream={onProducerFinishedStream}
      onChangeConstraints={onChangeConstraints}
      slotControlButtons={controlButton}
    />
  );
}

export default AppointmentStreamController;
