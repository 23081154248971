import { useCallback, useEffect, useState } from "react";
import { AppointmentSlot, InputAppointmentSlotSearch } from "@scrile/api-provider/dist/api/AppointmentSlotProvider";
import { getDayOfYear } from "date-fns";
import providers from "../../../lib/providers";
import { AppointmentSlotMap } from "../types";

export default function useAppointmentSlot() {
  const [appointmentSlotsSearchResult, setAppointmentSlotsSearchResult] = useState<AppointmentSlot[]>([]);
  const [appointmentSlotMap, setAppointmentSlotMap] = useState<AppointmentSlotMap>();

  const getAppointmentSlotMap = useCallback((): AppointmentSlotMap => {
    return appointmentSlotsSearchResult.reduce<AppointmentSlotMap>((acc, slot) => {
      const key = getDayOfYear(new Date(slot.startTime));

      if (Array.isArray(acc[key])) {
        acc[key].push(slot);
        return acc;
      }
      acc[key] = [slot];
      return acc;
    }, {});
  }, [appointmentSlotsSearchResult]);

  const [loading, setLoading] = useState<boolean>(false);
  const getAppointmentSlots = (data: InputAppointmentSlotSearch): void => {
    setLoading(true);
    providers.AppointmentSlotProvider.search({ data: data })
      .then(setAppointmentSlotsSearchResult)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setAppointmentSlotMap(getAppointmentSlotMap());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentSlotsSearchResult, setAppointmentSlotMap]);

  return {
    loading,
    appointmentSlotMap,
    getAppointmentSlots,
  };
}
