import { useEffect, useRef } from "react";
import useStreamHelper from "../../../../hooks/useStreamHelper";
import { stopTracks } from "../../../../lib/mediaStreamHelpers";

export default function useViewController(stream?: MediaStream) {
  const localStream = useRef<MediaStream | undefined>(stream);
  const { checkStreamForChanges } = useStreamHelper();

  const stopLocalTracks = () => {
    localStream.current && stopTracks(localStream.current);
  };

  useEffect(() => {
    if (!stream || !localStream.current) return;
    checkStreamForChanges(stream, localStream.current);
  }, [stream, checkStreamForChanges]);

  return {
    localStream: localStream.current,
    stopLocalTracks,
  };
}
