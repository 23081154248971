/* eslint-disable @typescript-eslint/camelcase */
export default {
  "Credit": "Credit",
  "Credit_plural": "Credits",
  "NewMessage": "New message",
  "NewMessage_plural": "{{count}}  New messages",
  "minute": "{{count}} minute",
  "minute_plural": "{{count}} minutes",
  "paypal": "PayPal",

  "Customer": "Сandidate",
  "Member list": "Candidate list",
  "By clicking Start you will start a new live chat. Members will see that you're online and will be able to join the live chat. You can also copy and share chat invite link or send it via email.":
    "By clicking Start you will start a new live chat. Candidates will see that you're online and will be able to join the live chat. You can also copy and share chat invite link or send it via email.",
  "Thank you! Everything is set up. Customers can now book a meeting with you using the «Book now» button on your profile page.":
    "Thank you! Everything is set up. Candidates can now book a meeting with you using the «Book now» button on your profile page.",
  "With Calendly, customers can book appointments with you on their own with a couple of clicks of the mouse. Please register with Calendly by following the instructions on the website. It's really simple and fast.":
    "With Calendly, candidates can book appointments with you on their own with a couple of clicks of the mouse. Please register with Calendly by following the instructions on the website. It's really simple and fast.",

  "Consultant": "Coach",
  "Consultants": "Coaches",
  "Find a Consultant": "Find a Coach",
  "Your favorite consultant <screenName>{{screenName}}</screenName> has just started a live session":
    "Your favorite coach <screenName>{{screenName}}</screenName> has just started a live session",
  "Your review will be displayed in the <profile>Consultant’s profile</profile>":
    "Your review will be displayed in the <profile>Coach’s profile</profile>",
  "The consultant has not provided the services yet, but you can get a consultation by asking a question.":
    "The coach has not provided the services yet, but you can get a consultation by asking a question.",
  "Become a consultant": "Become a coach",
  "SecureTheOffer is founded by Wasim Tahir, a former Oliver Wyman and Boston Consulting Group consultant, with extensive experience across several financial institutions including Jeffries & Company, Credit Suisse, Lloyd’s Banking Group and CDC Group.":
    "SecureTheOffer is founded by Wasim Tahir, a former Oliver Wyman and Boston Consulting Group consultant, with extensive experience across several financial institutions including Jeffries & Company, Credit Suisse, Lloyd’s Banking Group and CDC Group.",
  "shortSundaySymbol": "s",
  "shortMondaySymbol": "m",
  "shortTuesdaySymbol": "t",
  "shortWednesdaySymbol": "w",
  "shortThursdaySymbol": "t",
  "shortFridaySymbol": "f",
  "shortSaturdaySymbol": "s",
};
