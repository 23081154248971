/* eslint-disable react/display-name */
import React, { useCallback, useMemo } from "react";
import { Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import { LocationState } from "../../types";
import useCustomDomain from "../../hooks/useCustomDomain";
import ModalWindow from "../../components/ModalWindow";
import ForgotPassword from "../../modals/ForgotPassword";
import ClientLogin from "../../modals/ClientLogin";
import ClientSignUp from "../../modals/ClientSignUp";
import EmailVerificationByCode from "../../modals/EmailVerificationByCode";
import PagePerformers from "../../pages/PagePerformers";
import LinkedModalPageView from "../LinkedModalPageView";
import PagePerformerProfile from "../../pages/PagePerformerProfile";
import PageLoginTwitter from "../../pages/PageLoginTwitter";
import ConfirmEmail from "../../modals/ConfirmEmail";
import PageSettings from "../../pages/PageUserArea";
import PageLogout from "../../pages/PageLogout";
import PageMessages from "../../pages/PageMessages";
import PageLiveChat from "../../pages/PageLiveChat";
import PageAppointmentChat from "../../pages/PageAppointmentChat";
import PageLoginToken from "../../pages/PageLoginToken";
import PageLanding from "../../pages/PageLanding";
import PageFAQ from "../../pages/PageFAQ";
import PageAboutUs from "../../pages/PageAboutUs";
import PageBlog from "../../pages/PageBlog";
import PerformerVerification from "../../modals/PerformerVerification";
import GoAppointmentChatModal from "../../modals/GoAppointmentChatModal";

interface LinkedModalDescription {
  path: string;
  Component: React.FC;
  meta?: {
    authOnly?: boolean;
    modal?: boolean;
  };
  comparator?: (currentPath: string) => boolean;
}

interface RouterDescription {
  path: string;
  exact?: boolean;
  Component: React.FC;
}

export default function MainRouterView() {
  const location = useLocation<LocationState>();
  const history = useHistory<LocationState>();
  const backgroundLocation = location.state?.location;
  const onModalClose = useCallback(() => history.replace(backgroundLocation || { pathname: "/" }), [
    history,
    backgroundLocation,
  ]);

  const modals: LinkedModalDescription[] = useMemo(
    () => [
      {
        path: "/login",
        Component: () => <ClientLogin onClose={onModalClose} />,
      },
      {
        path: "/login/forgot",
        Component: () => <ForgotPassword onCancel={onModalClose} />,
      },
      {
        path: "/login/forgot/:email",
        Component: () => {
          const { email } = useParams<{ email: string }>();
          return <ForgotPassword email={email} onCancel={onModalClose} />;
        },
      },
      {
        path: "/login/forgot/:email/:token",
        Component: () => {
          const { token } = useParams<{ token: string }>();
          return <ForgotPassword token={token} onCancel={onModalClose} />;
        },
      },
      {
        path: "/signup",
        Component: () => <ClientSignUp onClose={onModalClose} />,
      },
      {
        path: "/signup/coach",
        Component: () => <ClientSignUp onClose={onModalClose} role={"performer"} />,
      },
      {
        path: "/signup/coach/form",
        Component: () => <PerformerVerification onClose={onModalClose} />,
        meta: {
          modal: location.pathname === "/signup/coach/form",
        },
      },
      {
        path: "/signup/email",
        Component: () => <ConfirmEmail onCancel={onModalClose} />,
        meta: {
          modal: location.pathname === "/signup/email",
        },
      },
      {
        path: "/signup/confirm",
        Component: () => {
          const search = new URLSearchParams(location.search);
          const code = search.get("code");
          return <EmailVerificationByCode onClose={onModalClose} code={code} />;
        },
        meta: {
          modal: location.pathname === "/signup/confirm",
        },
      },
      {
        path: "/appointment/join/:appointmentId",
        Component: () => {
          const { appointmentId } = useParams<{ appointmentId: string }>();
          return <GoAppointmentChatModal onClose={onModalClose} appointmentId={appointmentId} />;
        },
        meta: { modal: true },
        comparator: (currentPath) => /\/appointment\/join\/\d+/.test(currentPath),
      },
    ],
    [location, onModalClose]
  );

  const { user, isCustomDomain } = useCustomDomain();

  const routes = useMemo(() => {
    const routes: RouterDescription[] = [];

    if (isCustomDomain) {
      routes.push({
        path: "/live",
        Component: () => <PageLiveChat username={user ? user.username : ""} />,
      });
    } else {
      routes.push(
        {
          path: "/coaches",
          exact: true,
          Component: () => <PagePerformers key="performers" />,
        },
        {
          path: "/favorites",
          Component: () => <PagePerformers onlyFollowings={true} key="favorites" />,
        }
      );
    }

    routes.push(
      {
        path: "/login/token/:token",
        Component: () => {
          const { token } = useParams<{ token: string }>();
          return <PageLoginToken token={token} />;
        },
      },
      {
        path: "/faq",
        Component: () => <PageFAQ />,
      },
      {
        path: "/about-us",
        Component: () => <PageAboutUs />,
      },
      {
        path: "/:username/live",
        Component: () => {
          const { username } = useParams<{ username: string }>();
          return <PageLiveChat username={username} />;
        },
      },
      {
        path: "/:username",
        Component: () => {
          const { username } = useParams<{ username: string }>();
          return <PagePerformerProfile username={username} />;
        },
      },
      {
        path: "/",
        Component: () =>
          isCustomDomain ? <PagePerformerProfile username={user ? user.username : ""} /> : <PageLanding />,
      }
    );

    return routes;
  }, [isCustomDomain, user]);

  const currentModal = modals.find((m) =>
    m.comparator ? m.comparator(location.pathname) : m.path === location.pathname
  );

  return (
    <>
      <Switch location={backgroundLocation || location}>
        {!backgroundLocation &&
          modals.map((data) => (
            <Route path={data.path} exact key={data.path}>
              <LinkedModalPageView path={location.pathname} />
            </Route>
          ))}
        <Route path={"/user"}>
          <PageSettings />
        </Route>
        <Route path={"/logout"}>
          <PageLogout />
        </Route>
        <Route path={"/login/twitter"}>
          <PageLoginTwitter />
        </Route>
        <Route path={"/messages"}>
          <PageMessages />
        </Route>
        <Route path={"/appointment/:appointmentId"}>
          <PageAppointmentChat />
        </Route>
        <Route path={"/insights"}>
          <PageBlog />
        </Route>
        {routes.map(({ path, exact, Component }) => (
          <Route path={path} exact={exact} key={path}>
            <Component />
          </Route>
        ))}
      </Switch>
      {backgroundLocation && (
        <ModalWindow show modal={currentModal?.meta?.modal} onClose={onModalClose}>
          {modals.map(({ path, Component }) => (
            <Route path={path} exact key={path}>
              <Component />
            </Route>
          ))}
        </ModalWindow>
      )}
    </>
  );
}
