import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { BaseMenuItemInterface } from "../../types";
import useComponentVisible from "../../hooks/useComponentVisible";
import BaseIcon from "../BaseIcon";
import BaseDivider from "../BaseDivider";
import "./style.scss";

interface Props {
  children?: string | JSX.Element;
  prepend?: React.FC | ReactNode;
  className?: string;
  title?: string;
  onClick?: (item: string) => void;
  itemsList: BaseMenuItemInterface[];
  spaced?: boolean;
}

function BaseMenu({ children, className = "", itemsList, onClick, title, spaced, prepend }: Props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const cls = ["base-menu"];
  if (spaced) cls.push("__spaced");
  cls.push(className);

  if (isComponentVisible) cls.push("__active");

  const toggleMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const onClickItem = (name: string) => {
    setIsComponentVisible(false);
    onClick && onClick(name);
  };

  return (
    <div className={cls.join(" ")} ref={ref}>
      <div className="base-menu__activator-container">
        <div className="base-menu__activator" onClick={toggleMenu}>
          {children ? (
            children
          ) : (
            <div className="base-menu__activator-default">
              <BaseIcon size={12.5}>fas-angle-down</BaseIcon>
            </div>
          )}
        </div>
      </div>
      <div className="base-menu__content">
        {isComponentVisible ? (
          <ul className="base-menu__list">
            {title ? <li className="base-menu__item __title">{title}</li> : null}
            {prepend && (
              <div onClick={() => onClickItem("prepend")} className="mt-0 mb-0">
                {prepend}
              </div>
            )}
            {itemsList.map((item) => {
              const MenuItem = (props: React.PropsWithoutRef<{}>) => (
                <li className="base-menu__item" onClick={() => onClickItem(item.name)} {...props}>
                  {item.icon ? (
                    <div className="base-menu__item-icon-container">
                      <BaseIcon>{item.icon}</BaseIcon>
                    </div>
                  ) : null}
                  {item.title}
                </li>
              );
              return item.to ? (
                <React.Fragment key={item.name}>
                  <Link to={item.to}>
                    <MenuItem />
                  </Link>
                  {item.divider && <BaseDivider className="mt-3 mb-2" />}
                </React.Fragment>
              ) : (
                <React.Fragment key={item.name}>
                  <MenuItem />
                  {item.divider && <BaseDivider className="mt-3 mb-2" />}
                </React.Fragment>
              );
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );
}

export default BaseMenu;
