import { useMemo } from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import useMessages from "../../../../hooks/useMessages";

const useViewController = (user: UserPublicData) => {
  const { getMessageUrl } = useMessages();

  const askLink = useMemo(() => getMessageUrl(user), [user, getMessageUrl]);
  const showButtons = useMemo(() => askLink, [askLink]);
  const firmName = useMemo(
    () =>
      user.questionary?.fields.professional?.jobs[user.questionary.fields.professional.jobs.length - 1].firm ?? null,
    [user]
  );
  const universityName = useMemo(
    () =>
      user.questionary?.fields.academic?.educations[user.questionary.fields.academic.educations.length - 1]
        .university ?? null,
    [user]
  );

  return {
    askLink,
    showButtons,
    firmName,
    universityName,
  };
};

export default useViewController;
