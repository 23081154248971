import { useRef, useState } from "react";
import { PaymentPackage } from "@scrile/api-provider/dist/api/PaymentPackageProvider";
import { AppointmentType } from "@scrile/api-provider/dist/api/AppointmentTypeProvider";
import { AppointmentSlot } from "@scrile/api-provider/dist/api/AppointmentSlotProvider";
import { getDuration } from "../../../../../../../lib/timeHelper";
import { getPrice } from "../../../../../../../lib/moneyHelper";
import { RangeTime } from "../../../types";

export default function useAppointmentBook(appointmentTypes: AppointmentType[]) {
  const [currentDurationPrice, setCurrentDurationPrice] = useState<PaymentPackage | null>(null);
  const [bookTime, setBookTime] = useState<AppointmentSlot["startTime"] | null>(null);
  const [keyOfActiveDay, setKeyOfActiveDay] = useState<number>(0);
  const selectedRangeTime = useRef<RangeTime | null>(null);

  const getAppointmentTypeId = () => {
    return appointmentTypes[0]?.id;
  };

  const getBookButtonText = (): string => {
    const duration = getDuration(
      appointmentTypes.find((a) => a.pricePackageId === currentDurationPrice?.id)?.duration ?? 0
    );
    return `Book ${duration} for ${getPrice(currentDurationPrice?.amount ?? 0)}`;
  };

  return {
    bookTime,
    keyOfActiveDay,
    selectedRangeTime,
    currentDurationPrice,
    setBookTime,
    getBookButtonText,
    setKeyOfActiveDay,
    getAppointmentTypeId,
    setCurrentDurationPrice,
  };
}
