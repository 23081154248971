import { useState } from "react";
import { Appointment } from "@scrile/api-provider/dist/api/AppointmentProvider";
import { InputAppointmentSlotBook } from "@scrile/api-provider/dist/api/AppointmentSlotProvider";
import emitter, { EVENTS } from "../../../lib/emitter";
import providers from "../../../lib/providers";
import useAppState from "../../../hooks/useAppState";

export default function useController() {
  const [loading, setLoading] = useState(false);
  const { balance } = useAppState();

  const onRefillBalance = () => emitter.emit(EVENTS.BALANCE_REFILL_SHOW);

  const payForAppointment = async (data: InputAppointmentSlotBook): Promise<Appointment> => {
    setLoading(true);
    try {
      return await providers.AppointmentSlotProvider.book({ data: data });
    } finally {
      setLoading(false);
    }
  };

  return {
    balance,
    loading,
    onRefillBalance,
    payForAppointment,
  };
}
