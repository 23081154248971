import React, { useEffect, useMemo, useState } from "react";
import { PaymentPackage } from "@scrile/api-provider/dist/api/PaymentPackageProvider";
import { AppointmentType } from "@scrile/api-provider/dist/api/AppointmentTypeProvider";
import { FormValue } from "../../../../../../types";
import { t } from "../../../../../../locales";
import BaseSelect from "../../../../../../components/BaseSelect";
import { getDuration } from "../../../../../../lib/timeHelper";
import { getPrice } from "../../../../../../lib/moneyHelper";
import { PriceDate } from "../AppointmentBookingController/types";
import { DurationPriceOptionItem } from "./types";
import { getInitialDurationPriceValue } from "./utils";

type Props = {
  className?: string;
  appointmentTypes: AppointmentType[];
  paymentPackages: PaymentPackage[];
  onChangePrice?: (priceData: PriceDate) => void;
};

export default function AppointmentDurationPrice({
  className,
  appointmentTypes,
  paymentPackages,
  onChangePrice,
}: Props) {
  const cls = ["appointment-duration-price"];
  if (className) cls.push(className);

  const durationPriceOptions = useMemo<DurationPriceOptionItem[]>(() => {
    return appointmentTypes.map((p) => {
      const amount = paymentPackages.find((i) => i.id === p.pricePackageId)?.amount ?? 0;
      return {
        text: `${getDuration(p.duration)} / ${getPrice(amount)}`,
        value: p.pricePackageId,
        meta: { duration: p.duration },
      };
    });
  }, [appointmentTypes, paymentPackages]);

  const [durationPriceValue, setDurationPriceValue] = useState<FormValue>(
    getInitialDurationPriceValue(durationPriceOptions)
  );

  useEffect(() => {
    !!onChangePrice &&
      onChangePrice({
        paymentPackage: paymentPackages.find((i) => i.id === durationPriceValue) ?? null,
        appointmentType: appointmentTypes.find((i) => i.pricePackageId === durationPriceValue) ?? null,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [durationPriceValue]);

  return (
    <BaseSelect
      className={cls.join(" ")}
      name={"duration-price"}
      label={t("Duration / Price")}
      placeholder={t("Empty")}
      value={durationPriceValue}
      options={durationPriceOptions}
      onChange={setDurationPriceValue}
    />
  );
}
