import { useCallback, useEffect, useRef, useState } from "react";
import { isEqual, uniqBy } from "lodash-es";
import { useUnmount } from "react-use";
import { WebRTCConnection } from "@scrile/api-provider/dist/api/WebRTCProvider";
import { LiveChatJoinResponse } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { MediaConstraints } from "../types";
import useWebRTC from "./useWebRTC";
import providers from "../lib/providers";
import useBlurController from "./useBlurController";
import useMediaConstraints from "./useMediaConstraints";
import {
  addTracksToStream,
  cloneScreenTracks,
  getScreenTracks,
  getVideoTracks,
  removeTracksFromStream,
} from "../lib/mediaStreamHelpers";
import { SendDataEvent } from "./useProduceConsumeController";

interface ProduceProps {
  token: string;
  joinData: LiveChatJoinResponse | null;
  autoProduceStream: boolean;
}

function useProduceController({ token, joinData, autoProduceStream }: ProduceProps) {
  const WebRTC = useWebRTC();
  const { blurStart, blurStop } = useBlurController();
  const [stream, setStream] = useState<MediaStream>(new MediaStream([]));
  const [screenStream, setScreenStream] = useState<MediaStream>(new MediaStream([]));
  const [streamToProduce, setStreamToProduce] = useState<MediaStream>(new MediaStream([]));
  const [loading, setLoading] = useState(false);
  const connection = useRef<WebRTCConnection>();
  const [processing, setProcessing] = useState(false);
  const [audioInputs, setAudioInputs] = useState<MediaDeviceInfo[]>([]);
  const [videoInputs, setVideoInputs] = useState<MediaDeviceInfo[]>([]);
  const { mediaConstraints, setMediaConstraints } = useMediaConstraints({
    video: true,
    audio: true,
    videoEnabled: autoProduceStream,
    audioEnabled: autoProduceStream,
    shareEnabled: false,
    blurEnabled: false,
  });

  const setStreams = (stream: MediaStream) => {
    setStreamToProduce(new MediaStream(stream));
    setStream(new MediaStream(getVideoTracks(stream)));
    setScreenStream(new MediaStream(getScreenTracks(stream)));
  };

  const onScreenShare = async (constraints: MediaConstraints) => {
    let data: MediaStreamConstraints | MediaStream;
    if (constraints.shareEnabled) {
      // @ts-ignore
      const screen = (await navigator.mediaDevices.getDisplayMedia()) as MediaStream;
      const screenTracks = screen.getVideoTracks();
      // @ts-ignore
      screenTracks.forEach((t) => (t.kindType = "screen"));
      data = new MediaStream([...screen.getTracks(), ...streamToProduce.getVideoTracks()]);
      addTracksToStream(data, streamToProduce.getAudioTracks());
    } else {
      removeTracksFromStream(streamToProduce, getScreenTracks(streamToProduce));
      data = new MediaStream(streamToProduce.getVideoTracks());
      addTracksToStream(data, streamToProduce.getAudioTracks());
    }
    return data;
  };

  const onVideoChange = async (constraints: MediaConstraints) => {
    let data: MediaStream;

    removeTracksFromStream(streamToProduce, getVideoTracks(streamToProduce));
    if (!constraints.videoEnabled) {
      data = new MediaStream(streamToProduce.getVideoTracks());
      addTracksToStream(data, streamToProduce.getAudioTracks());
    } else {
      const newStream = new MediaStream(await navigator.mediaDevices.getUserMedia({ video: constraints.video }));
      addTracksToStream(newStream, streamToProduce.getAudioTracks());
      cloneScreenTracks(getScreenTracks(streamToProduce)).forEach((t) => newStream.addTrack(t));
      data = newStream;
    }
    return data;
  };

  const onAudioChange = async (constraints: MediaConstraints) => {
    if (!isEqual(mediaConstraints.audio, constraints.audio) || !constraints.audioEnabled) {
      removeTracksFromStream(streamToProduce, streamToProduce.getAudioTracks());
    }
    if (constraints.audioEnabled) {
      const newStream = await navigator.mediaDevices.getUserMedia({
        audio: constraints.audio,
      });
      addTracksToStream(streamToProduce, newStream.getAudioTracks(), true);
    }
    return streamToProduce;
  };

  const onBlurChange = useCallback(
    async (constraints: MediaConstraints, outStream = streamToProduce) => {
      let data: MediaStream;

      if (constraints.blurEnabled) {
        const videoTracks = getVideoTracks(outStream);
        if (!videoTracks.length) {
          constraints.blurEnabled = false;
          return outStream;
        }
        data = await blurStart(videoTracks[0]);
      } else {
        data = await blurStop();
      }

      addTracksToStream(data, outStream.getAudioTracks());
      cloneScreenTracks(getScreenTracks(streamToProduce)).forEach((t) => data.addTrack(t));
      return data;
    },
    [blurStart, blurStop, streamToProduce]
  );

  const setDevicesList = useCallback(async () => {
    const { audioInputList, videoInputList } = await WebRTC.getDeviceList();
    setAudioInputs(uniqBy(audioInputList.reverse(), "groupId").reverse());
    setVideoInputs(videoInputList);
  }, [WebRTC]);

  const closeStream = async () => {
    await onProducerFinishedStream();
    WebRTC.closeAllStreams();
    streamToProduce.getTracks().forEach((t) => t.stop());
    stream.getTracks().forEach((t) => t.stop());
    screenStream.getTracks().forEach((t) => t.stop());
  };

  const onProduceStream = useCallback(async () => {
    try {
      setLoading(true);
      if (!token) return false;
      const [connectionData] = await Promise.all([providers.LivechatsProvider.broadcast({ token }), setDevicesList()]);
      connection.current = connectionData;

      let localStream = new MediaStream([]);
      if (mediaConstraints.videoEnabled || mediaConstraints.audioEnabled) {
        localStream = await navigator.mediaDevices.getUserMedia({
          video: mediaConstraints.videoEnabled ? mediaConstraints.video : false,
          audio: mediaConstraints.audioEnabled ? mediaConstraints.audio : false,
        });

        if (mediaConstraints.blurEnabled) {
          localStream = await onBlurChange({ ...mediaConstraints }, localStream);
        }
      }

      localStream = await WebRTC.produceStream(connection.current, localStream);
      setStreams(localStream);
      return true;
    } finally {
      setLoading(false);
    }
  }, [WebRTC, setDevicesList, token, mediaConstraints, onBlurChange]);

  const onChangeConstraints = async (constraints: MediaConstraints) => {
    try {
      setProcessing(true);
      let data: MediaStream | null = null;
      const localConstraints: MediaConstraints = {
        ...mediaConstraints,
        ...constraints,
      };
      if (!isEqual(mediaConstraints.blurEnabled, localConstraints.blurEnabled)) {
        data = await onBlurChange(localConstraints);
      } else if (!isEqual(mediaConstraints.shareEnabled, localConstraints.shareEnabled)) {
        data = await onScreenShare(localConstraints);
      } else if (
        !isEqual(mediaConstraints.videoEnabled, localConstraints.videoEnabled) ||
        !isEqual(mediaConstraints.video, localConstraints.video)
      ) {
        if (localConstraints.blurEnabled) {
          await blurStop();
        }
        data = await onVideoChange(localConstraints);
        if (localConstraints.blurEnabled && localConstraints.videoEnabled) {
          data = await onBlurChange(localConstraints, data);
        }
      } else if (
        !isEqual(mediaConstraints.audio, localConstraints.audio) ||
        !isEqual(mediaConstraints.audioEnabled, localConstraints.audioEnabled)
      ) {
        data = await onAudioChange(localConstraints);
      }
      setMediaConstraints(localConstraints);

      if (!connection.current || !data) return;
      const newStream = await WebRTC.produceStream(connection.current, data);
      setStreams(newStream);
    } finally {
      setProcessing(false);
    }
  };

  const shouldCallProduceStream = useRef(true);
  useEffect(() => {
    if (joinData && shouldCallProduceStream.current && autoProduceStream) {
      shouldCallProduceStream.current = false;
      onProduceStream();
    }
  }, [joinData, onProduceStream, autoProduceStream]);

  useUnmount(closeStream);

  const onProducerFinishedStream = async () => {
    await WebRTC.webRTCData?.producer.client?.sendData({ event: SendDataEvent.PRODUCER_FINISHED_STREAM });
  };

  return {
    processing,
    loading,
    screenStream,
    stream,
    streamToProduce,
    connection: connection.current,
    mediaConstraints,
    audioInputs,
    videoInputs,
    isShare: !!mediaConstraints.shareEnabled,
    onProduceStream,
    onScreenShare,
    onProducerFinishedStream,
    onChangeConstraints,
    closeStream,
  };
}

export default useProduceController;
