import { useEffect, useState } from "react";
import {
  AppointmentType,
  AppointmentTypeList,
  InputAppointmentType,
} from "@scrile/api-provider/dist/api/AppointmentTypeProvider";
import { PaymentPackage, PaymentPackageEnum } from "@scrile/api-provider/dist/api/PaymentPackageProvider";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import providers from "../lib/providers";
import useAuthUser from "./useAuthUser";

export default function useAppointments(userId?: UserPublicData["id"]) {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(true);
  const [availability, setAvailability] = useState(false);
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>([]);
  const [paymentPackages, setPaymentPackages] = useState<PaymentPackage[]>([]);
  const { user: authUser } = useAuthUser();

  const updateAppointmentTypes = async (data: InputAppointmentType[]) => {
    if (!userId) {
      console.error("UserId is undefined");
      return;
    }
    try {
      setProcessing(true);
      const userAppointmentTypes = await providers.AppointmentTypeProvider.update({
        data: { userId, types: data },
      });
      setAppointmentTypes(userAppointmentTypes.types);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (!authUser) return;
    try {
      setAvailability(true);
    } finally {
      setProcessing(false);
    }
  }, [authUser]);

  useEffect(() => {
    if (!userId) return;
    setLoading(true);
    Promise.all([
      providers.AppointmentTypeProvider.search({ data: { userIds: [userId] } }),
      providers.PaymentPackageProvider.findAll({ type: [PaymentPackageEnum.APPOINTMENT] }),
    ])
      .then(([appointmentTypeList, paymentPackages]) => {
        const userAppointmentTypes = appointmentTypeList.find((l: AppointmentTypeList) => l.userId === userId);
        if (userAppointmentTypes) setAppointmentTypes(userAppointmentTypes.types);
        setPaymentPackages(paymentPackages);
      })
      .finally(() => setLoading(false));
  }, [userId]);

  return { loading, processing, availability, appointmentTypes, paymentPackages, updateAppointmentTypes };
}
