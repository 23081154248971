import React from "react";
import { t } from "../../../../../../locales";
import { DayOfWeek, InputInterval, UserAvailabilityDisplayInterval } from "../AppointmentsAvailability/types";
import BaseSelect from "../../../../../../components/BaseSelect";
import BaseButton from "../../../../../../components/BaseButton";
import BaseIcon from "../../../../../../components/BaseIcon";
import BaseToggleButton from "../../../../../../components/BaseToggleButton";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  dayOfWeek?: number;
  intervals: UserAvailabilityDisplayInterval[];
  onNewInterval: (dayOfWeek: DayOfWeek) => void;
  onChangeBeginTime: (data: InputInterval) => void;
  onChangeEndTime: (data: InputInterval) => void;
  onRemove: (v: number) => void;
  onMultipleRemove: (v: number[]) => void;
  withLabels?: boolean;
  className?: string;
}

const AvailabilityIntervals = ({
  dayOfWeek = -1,
  intervals,
  onNewInterval,
  onMultipleRemove,
  onChangeBeginTime,
  onChangeEndTime,
  onRemove,
  withLabels = true,
  className = "",
}: Props) => {
  const { daysOfWeekItems, range12HoursOptions, selectOptionAdapter } = useViewController();

  const cls = ["availability-intervals d-flex __column __align-start"];
  cls.push(className);

  const handleOnChange = () => {
    if (!intervals.length) {
      onNewInterval(dayOfWeek);
    } else {
      const indexes = intervals.map((interval) => interval.index);
      onMultipleRemove(indexes);
    }
  };
  return (
    <div className={cls.join(" ")}>
      <BaseToggleButton
        labelOnLeft={true}
        value={!!intervals.length}
        onChange={handleOnChange}
        name={"daysOfWeekItems"}
      >
        <span className="fw-semibold">{daysOfWeekItems[dayOfWeek].text}</span>
      </BaseToggleButton>
      {!!intervals.length && (
        <div className="availability-intervals__intervals d-flex __column">
          {intervals.map((interval, i) => (
            <div className="availability-intervals__interval d-flex" key={i}>
              <BaseSelect
                name="startTimeSelect"
                className="availability-intervals__time-input __start"
                value={selectOptionAdapter(interval).startTime}
                onChange={(v) => onChangeBeginTime({ newTime: v, index: interval.index, displayIntervals: intervals })}
                options={range12HoursOptions}
                label={withLabels && !i ? t("From") : ""}
              />
              <BaseSelect
                name="endTimeSelect"
                className="availability-intervals__time-input __end"
                value={selectOptionAdapter(interval).endTime}
                onChange={(v) => onChangeEndTime({ newTime: v, index: interval.index, displayIntervals: intervals })}
                options={range12HoursOptions}
                label={withLabels && !i ? t("To") : ""}
              />
              <BaseButton
                icon
                borderless
                className="availability-intervals__remove my-1"
                onClick={() => onRemove(interval.index)}
              >
                <BaseIcon className="availability-intervals__remove-icon" size={"24"}>
                  close
                </BaseIcon>
              </BaseButton>
            </div>
          ))}
          {!!intervals.length && (
            <BaseButton
              className="availability-intervals__add-hours"
              borderless
              onClick={() => onNewInterval(dayOfWeek)}
            >
              <BaseIcon className="availability-intervals__icon mr-1">circle-plus</BaseIcon>
              <span>{t("Add hours")}</span>
            </BaseButton>
          )}
        </div>
      )}
    </div>
  );
};
export default AvailabilityIntervals;
