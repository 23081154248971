import React from "react";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";
import MediaSettings from "./components/MediaSettings";
import UserList from "./components/UserList";
import TextChat from "./components/TextChat";
import { MediaConstraints } from "../../../../types";
import "./style.scss";

export enum RightPanelView {
  HIDE_ALL,
  CHAT,
  USERS,
  SETTINGS,
}

interface Props {
  loading: boolean;
  token: string;
  threadId?: string;
  userList: LiveChatUser[];
  devices?: { audioInputs: MediaDeviceInfo[]; videoInputs: MediaDeviceInfo[] };
  onChangeConstraints?: (constraints: MediaConstraints) => void;
  constraints?: MediaConstraints | null;
  changeControls: (rightPanelView: RightPanelView) => void;
  showSection: RightPanelView;
  disableAttachments?: boolean;
}

const RightPanel = ({
  loading,
  token,
  threadId,
  userList,
  devices,
  onChangeConstraints,
  constraints,
  changeControls,
  showSection,
  disableAttachments,
}: Props) => {
  const cls = ["right-panel"];
  if (showSection === RightPanelView.CHAT) cls.push("__chat-mode");

  return (
    <div className={cls.join(" ")}>
      <TextChat
        threadId={threadId}
        token={token}
        disableAttachments={disableAttachments}
        className={
          "right-panel__section __text-chat" +
          (showSection === RightPanelView.SETTINGS || showSection === RightPanelView.USERS ? " __hide" : "")
        }
      />
      {devices && (
        <MediaSettings
          loading={loading}
          audioInputs={devices.audioInputs}
          videoInputs={devices.videoInputs}
          className={"right-panel__section px-3 py-4" + (showSection === RightPanelView.SETTINGS ? "" : " __hide")}
          onChangeConstraints={onChangeConstraints}
          constraints={constraints}
          onClose={() => changeControls(RightPanelView.HIDE_ALL)}
        />
      )}
      {userList && (
        <UserList
          className={"right-panel__section" + (showSection === RightPanelView.USERS ? "" : " __hide")}
          userList={userList}
          onClose={() => changeControls(RightPanelView.HIDE_ALL)}
        />
      )}
    </div>
  );
};

export default RightPanel;
