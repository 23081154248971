import React, { useEffect, useRef } from "react";
import { useUnmount } from "react-use";
import useResizeVideo from "./hooks/useResizeVideo";
import "./style.scss";

interface Props {
  stream: MediaStream;
  hasVideo?: boolean;
  hasAudio?: boolean;
  className?: string;
  mirrorReflection?: boolean;
}

export default function SecondStreamView({ stream, hasVideo, hasAudio, mirrorReflection }: Props) {
  const videoWrapper = useRef<HTMLDivElement>(null);
  const player = useRef<HTMLVideoElement>(null);

  useResizeVideo({ player, videoWrapper, excludeParameter: ["width", "height"] });
  useEffect(() => {
    if (player.current && stream) {
      player.current.pause();
      player.current.srcObject = stream;
      player.current.play().then();
    }
  }, [stream, player]);

  const cls = ["second-stream-view"];
  if (!hasVideo || !stream.getVideoTracks().length) cls.push("__disabled-video");
  if (mirrorReflection) cls.push("__mirror-reflection");

  useUnmount(() => {
    player.current && (player.current.srcObject = null);
  });

  return (
    <div ref={videoWrapper} className={cls.join(" ")}>
      <video className="second-stream-view__video" ref={player} muted={!hasAudio} playsInline autoPlay />
    </div>
  );
}
