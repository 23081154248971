import React, { Fragment, useEffect } from "react";
import { useMount } from "react-use";
import BaseButton from "../BaseButton";
import BaseIcon from "../BaseIcon";
import useController from "./controller";
import "./style.scss";

export default function GoogleCalendarSync() {
  const { loading, handleStorageUpdate, getData, onClick, getButtonText } = useController();

  useEffect(() => {
    window.addEventListener("storage", handleStorageUpdate);
    return () => {
      window.removeEventListener("storage", handleStorageUpdate);
    };
  });

  useMount(async () => {
    await getData();
  });

  return (
    <Fragment>
      <BaseButton className={"google-calendar-sync"} outline large loading={loading} onClick={onClick}>
        <BaseIcon className={"google-calendar-sync__icon"}>google</BaseIcon>
        {getButtonText()}
      </BaseButton>
    </Fragment>
  );
}
