import React, { useEffect, useRef } from "react";
import { useUnmount } from "react-use";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import RippleAvatarAnimation from "../../../../components/StreamView/components/RippleAvatarAnimation";
import emitter, { EVENTS } from "../../../../lib/emitter";
import { MediaConstraints } from "../../../../types";
import "./style.scss";

interface Props {
  stream: MediaStream;
  user: UserPublicData;
  constraints: MediaConstraints | null;
  setPlayer: React.Dispatch<React.SetStateAction<HTMLVideoElement | null>>;
  className?: string;
  mirrorReflection?: boolean;
}

export default function MainStreamView({ stream, user, constraints, setPlayer, className, mirrorReflection }: Props) {
  const hasAudio = !!constraints?.audioEnabled;
  const hasVideo = !!constraints?.videoEnabled;
  const videoWrapper = useRef<HTMLDivElement>(null);
  const player = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (player.current) {
      player.current.pause();
      player.current.srcObject = stream;
      if (stream.getTracks().length && (hasVideo || hasAudio)) {
        player.current.play().catch((e) => {
          if (!player.current) throw e;
          setTimeout(
            () =>
              player.current?.play().catch((e) => {
                // eslint-disable-next-line no-console
                console.error(e);
                emitter.emit(EVENTS.USER_DID_NOT_INTERACT_DOC_FIRST, () => player.current?.play());
              }),
            500
          );
        });
      }
    }
  }, [hasAudio, hasVideo, stream]);

  useEffect(() => {
    setPlayer(player.current);
  }, [player, setPlayer]);

  const cls = ["main-stream-view"];
  if (!hasVideo) cls.push("__disabled-video");
  if (mirrorReflection) cls.push("__mirror_reflection");
  if (className) cls.push(className);

  useUnmount(() => {
    player.current && (player.current.srcObject = null);
  });

  return (
    <div ref={videoWrapper} className={cls.join(" ")}>
      <video className="main-stream-view__video" ref={player} muted={!hasAudio} playsInline autoPlay />
      {!hasVideo && <RippleAvatarAnimation className="main-stream-view__animation" size={60} user={user} />}
    </div>
  );
}
