import { useCallback, useEffect, useMemo, useState } from "react";
import { UserAvailabilityInterval } from "@scrile/api-provider/dist/api/UserAvailabilityProvider";
import {
  DayOfWeek,
  DEFAULT_BEGIN_MINUTES,
  DEFAULT_DURATION_MINUTES,
  InputInterval,
  MINUTES_IN_DAY,
  MINUTES_IN_WEEK,
  UserAvailabilityDisplayInterval,
} from "./types";
import useTimezone from "../../../../../../hooks/useTimezone";
import emitter, { EVENTS } from "../../../../../../lib/emitter";
import { NotificationData } from "../../../../../../components/PushManager";
import useController from "./controller";

const useViewController = () => {
  const { timezoneOffset, displayTimezone } = useTimezone();

  const { loading, processing, userAvailability, saveUserAvailability } = useController();

  const [showAddHours, setShowAddHours] = useState(false);
  const [intervals, setIntervals] = useState<UserAvailabilityInterval[]>([]);

  const normalizeMinutes = (
    minutes: number,
    amount: typeof MINUTES_IN_WEEK | typeof MINUTES_IN_DAY,
    returnZero = true
  ): number => {
    const result = (minutes >= 0 ? minutes : amount + minutes) % amount;
    return result === 0 && !returnZero ? amount : result;
  };

  const newIntervalDefaultValue: UserAvailabilityInterval = {
    beginMinutes: normalizeMinutes(DEFAULT_BEGIN_MINUTES - timezoneOffset, MINUTES_IN_WEEK),
    durationMinutes: DEFAULT_DURATION_MINUTES,
  };

  const [newInterval, setNewInterval] = useState<UserAvailabilityInterval>(newIntervalDefaultValue);

  const shiftDayOfWeek = (oldDay: DayOfWeek, newDay: DayOfWeek): number => (newDay - oldDay) * MINUTES_IN_DAY;

  const shiftMinutes = (oldTime: { hours: string; minutes: string }, newTime: string): number => {
    const [hours, minutes] = newTime.split(":").map((i) => Number(i));
    return (hours - Number(oldTime.hours)) * 60 + minutes - Number(oldTime.minutes);
  };

  const reduceIntervals = useCallback(
    (intervals: UserAvailabilityInterval[]): Record<DayOfWeek, UserAvailabilityDisplayInterval[]> => {
      return intervals.reduce(
        (
          displayIntervals: Record<DayOfWeek, UserAvailabilityDisplayInterval[]>,
          intervalData: UserAvailabilityInterval,
          index: number
        ) => {
          const beginMinutes = normalizeMinutes(intervalData.beginMinutes + timezoneOffset, MINUTES_IN_WEEK);
          const endMinutes = normalizeMinutes(beginMinutes + intervalData.durationMinutes, MINUTES_IN_WEEK);
          const dayOfWeek: DayOfWeek = Math.floor(beginMinutes / MINUTES_IN_DAY);
          const displayInterval: UserAvailabilityDisplayInterval = {
            beginTime: {
              hours: ("0" + Math.floor((beginMinutes % MINUTES_IN_DAY) / 60)).slice(-2),
              minutes: ("0" + ((beginMinutes % MINUTES_IN_DAY) % 60)).slice(-2),
            },
            endTime: {
              hours: ("0" + Math.floor((endMinutes % MINUTES_IN_DAY) / 60)).slice(-2),
              minutes: ("0" + ((endMinutes % MINUTES_IN_DAY) % 60)).slice(-2),
            },
            dayOfWeek,
            index,
          };

          displayIntervals[dayOfWeek] = displayIntervals[dayOfWeek]
            ? [...displayIntervals[dayOfWeek], displayInterval]
            : [displayInterval];
          return displayIntervals;
        },
        {} as Record<DayOfWeek, UserAvailabilityDisplayInterval[]>
      );
    },
    [timezoneOffset]
  );

  const displayAvailability = useMemo<Record<DayOfWeek, UserAvailabilityDisplayInterval[]>>(() => {
    return reduceIntervals(intervals);
  }, [intervals, reduceIntervals]);

  const availabilitySlots = useMemo(() => {
    const res: Record<string, UserAvailabilityDisplayInterval[]> = {};
    for (const dayOfWeekKey in DayOfWeek) {
      if (!isNaN(Number(dayOfWeekKey))) {
        res[dayOfWeekKey] = displayAvailability[(dayOfWeekKey as unknown) as DayOfWeek]
          ? displayAvailability[(dayOfWeekKey as unknown) as DayOfWeek]
          : [];
      }
    }
    return res;
  }, [displayAvailability]);

  const displayNewInterval = useMemo<UserAvailabilityDisplayInterval>(
    () => Object.entries(reduceIntervals([newInterval]))[0][1][0],
    [newInterval, reduceIntervals]
  );

  const toggleAddHours = (value: boolean) => {
    if (!value) {
      setNewInterval(newIntervalDefaultValue);
    }
    setShowAddHours(value);
  };

  const addDefaultInterval = (day: DayOfWeek) => {
    setNewInterval(newIntervalDefaultValue);
    onNewIntervalSelectDay(day);
  };

  const onNewIntervalSelectDay = (newDayOfWeek: DayOfWeek) => {
    newInterval.beginMinutes = normalizeMinutes(
      newInterval.beginMinutes + shiftDayOfWeek(displayNewInterval.dayOfWeek, newDayOfWeek),
      MINUTES_IN_WEEK
    );
    setIntervals([...intervals, newInterval]);
    toggleAddHours(false);
  };

  const onRemoveInterval = (index: number) => {
    intervals.splice(index, 1);
    setIntervals([...intervals]);
  };
  const onRemoveIntervals = (indexes: number[]) => {
    const newIntervals = intervals.filter((_, index) => !indexes.includes(index));
    setIntervals([...newIntervals]);
  };

  const onBeginTimeChange = ({ displayIntervals, newTime, index }: InputInterval) => {
    const filteredIntervals = displayIntervals.filter((i) => i.index === index);
    const displayInterval = filteredIntervals.length > 0 ? filteredIntervals[0] : null;
    if (!displayInterval) return;
    const timeDifference = shiftMinutes(displayInterval.beginTime, newTime);
    intervals[index].beginMinutes = normalizeMinutes(intervals[index].beginMinutes + timeDifference, MINUTES_IN_WEEK);
    intervals[index].durationMinutes = normalizeMinutes(
      intervals[index].durationMinutes - timeDifference,
      MINUTES_IN_DAY,
      false
    );
    setIntervals([...intervals]);
  };

  const onEndTimeChange = ({ displayIntervals, newTime, index }: InputInterval) => {
    const filteredIntervals = displayIntervals.filter((i) => i.index === index);
    const displayInterval = filteredIntervals.length > 0 ? filteredIntervals[0] : null;
    if (!displayInterval) return;
    const timeDifference = shiftMinutes(displayInterval.endTime, newTime);
    intervals[index].durationMinutes = normalizeMinutes(
      intervals[index].durationMinutes + timeDifference,
      MINUTES_IN_DAY,
      false
    );
    setIntervals([...intervals]);
  };

  const onSave = async () => {
    await saveUserAvailability(intervals);
    emitter.emit<NotificationData>(EVENTS.NOTIFICATION_SHOW, { text: "Changes saved", image: { icon: "done" } });
    toggleAddHours(false);
  };

  useEffect(() => {
    setIntervals(userAvailability.intervals);
  }, [userAvailability]);

  return {
    processing,
    loading,
    displayTimezone,
    showAddHours,
    availabilitySlots,
    onRemoveInterval,
    onRemoveIntervals,
    onBeginTimeChange,
    onEndTimeChange,
    addDefaultInterval,
    onSave,
  };
};

export default useViewController;
