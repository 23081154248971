import React, { CSSProperties, useRef } from "react";
import { BaseCheckboxField } from "../../types";
import "./index.scss";

interface Props extends Omit<BaseCheckboxField, "type"> {
  value: boolean | string;
  onChange: (value: boolean) => void;
  children?: React.ReactChild;
  style?: CSSProperties;
  labelOnLeft?: boolean;
}
function BaseToggleButton({
  autofocus,
  name,
  value,
  onChange,
  children,
  className,
  style,
  labelClassName,
  labelOnLeft = false,
}: Props) {
  const ref = useRef<HTMLInputElement | null>(null);
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    onChange(value);
  };

  const cls = ["toggle-button"];
  if (className) cls.push(className);
  return (
    <div className={cls.join(" ")} style={style}>
      <label
        className={
          "toggle-button__container " + (labelClassName ? labelClassName : "") + (labelOnLeft ? " __reverse" : "")
        }
      >
        <input
          ref={ref}
          className={"toggle-button__input __input "}
          type="checkbox"
          checked={typeof value === "boolean" ? value : false}
          onChange={onChangeHandler}
          name={name}
          autoFocus={autofocus}
        />
        <span className={"__checkmark"} />
        {children}
      </label>
    </div>
  );
}

export default BaseToggleButton;
