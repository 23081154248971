import React, { useEffect, useRef } from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import emitter, { EVENTS } from "../../../../lib/emitter";
import BaseAvatar from "../../../../components/BaseAvatar";
import "./style.scss";

interface Props {
  user: UserPublicData;
  stream: MediaStream;
  hasVideo?: boolean;
  hasAudio?: boolean;
  setPlayer?: React.Dispatch<React.SetStateAction<HTMLVideoElement | null>>;
  className?: string;
  onClick?: () => void;
  hide?: boolean;
  mirrorReflection?: boolean;
}

export default function UserStreamItem({
  user,
  stream,
  hasVideo,
  hasAudio,
  className,
  hide,
  onClick,
  setPlayer,
  mirrorReflection,
}: Props) {
  const player = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (player.current) {
      player.current.pause();
      player.current.srcObject = stream;
      if (stream.getTracks().length && (hasVideo || hasAudio)) {
        player.current.play().catch((e) => {
          if (!player.current) throw e;
          setTimeout(
            () =>
              player.current?.play().catch((e) => {
                // eslint-disable-next-line no-console
                console.error(e);
                emitter.emit(EVENTS.USER_DID_NOT_INTERACT_DOC_FIRST, () => player.current?.play());
              }),
            500
          );
        });
      }
    }
  }, [hasAudio, hasVideo, stream]);

  useEffect(() => {
    setPlayer && setPlayer(player.current);
  }, [player, setPlayer]);

  const cls = ["user-stream-item d-center"];
  if (!hasVideo) cls.push("__disabled-video");
  if (hide) cls.push("hidden");
  if (mirrorReflection) cls.push("__mirror_reflection");
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")} onClick={onClick}>
      <video className="user-stream-item__video" ref={player} muted={!hasAudio} playsInline autoPlay />
      {!hasVideo && <BaseAvatar size={28} user={user} />}
      <div className="user-stream-item__screen-name pa-1 truncate-ellipsis">{user.screenName}</div>
    </div>
  );
}
