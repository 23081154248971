import React, { Fragment, PropsWithChildren } from "react";
import useBreakPoints from "../../../../hooks/useBreakPoints";
import ModalWindow from "../../../../components/ModalWindow";
import "./style.scss";

type Props = {
  className?: string;
  show: boolean;
  onClose: () => void;
};

export default function ModalForMobile({ className, children, show, onClose }: PropsWithChildren<Props>) {
  const { desktopUp } = useBreakPoints();

  const cls = ["modal-for-mobile"];
  if (className) cls.push(className);

  if (desktopUp) return <Fragment>{children}</Fragment>;
  return (
    <ModalWindow className={cls.join(" ")} show={!desktopUp && show} onClose={onClose}>
      <Fragment>{children}</Fragment>
    </ModalWindow>
  );
}
