import { useCallback, useMemo, useState } from "react";
import {
  AppointmentSearchResult,
  AppointmentStatus,
  InputAppointmentSearch,
} from "@scrile/api-provider/dist/api/AppointmentProvider";
import useAuthUser from "../../../../../../hooks/useAuthUser";
import providers from "../../../../../../lib/providers";
import { isPerformer } from "../../../../../../lib/usersHelper";

export interface AppointmentsTimeRange {
  startTimeFrom?: string;
  startTimeTo?: string;
  endTimeFrom?: string;
  endTimeTo?: string;
  sort?: string;
}

export default function useController({
  startTimeFrom,
  startTimeTo,
  endTimeFrom,
  endTimeTo,
  sort,
}: AppointmentsTimeRange) {
  const { user } = useAuthUser();
  const isCoach = useMemo(() => isPerformer(user), [user]);

  const [processing, setProcessing] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [appointmentSearchResult, setAppointmentSearchResult] = useState<AppointmentSearchResult>();

  const onPageChanged = useCallback(
    async (page: number) => {
      setProcessing(true);
      setCurrentPage(page);
      const data: InputAppointmentSearch = {
        ...(isCoach ? { providerId: user?.id } : { consumerId: user?.id }),
        page,
        size: 6,
        startTimeFrom,
        startTimeTo,
        endTimeFrom,
        endTimeTo,
        sort,
        status: [
          AppointmentStatus.NEW,
          AppointmentStatus.APPROVED,
          AppointmentStatus.COMPLETED,
          AppointmentStatus.NOSHOW,
        ],
      };
      try {
        const response = await providers.AppointmentProvider.search(data);
        setAppointmentSearchResult(response);
      } finally {
        setProcessing(false);
      }
    },
    [user, startTimeFrom, startTimeTo, endTimeFrom, endTimeTo, sort, isCoach]
  );

  const onCancelSuccess = useCallback(async () => {
    if (currentPage > 0 && (appointmentSearchResult?.result.length ?? 0) <= 1) {
      await onPageChanged(currentPage - 1);
    } else {
      await onPageChanged(currentPage);
    }
  }, [onPageChanged, currentPage, appointmentSearchResult]);

  return {
    appointments: appointmentSearchResult?.result || [],
    totalPages: appointmentSearchResult?.totalPages || 0,
    processing,
    onPageChanged,
    isCoach,
    onCancelSuccess,
  };
}
