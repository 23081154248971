import React, { Fragment, useRef, useState } from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { AppointmentType } from "@scrile/api-provider/dist/api/AppointmentTypeProvider";
import { PaymentPackage } from "@scrile/api-provider/dist/api/PaymentPackageProvider";
import PaymentBooking from "../../../../modals/PaymentBooking";
import AppointmentDescriptionModal from "../../../../modals/AppointmentDescriptionModal";
import AppointmentBookingController from "./components/AppointmentBookingController";
import { BookingData } from "./types";

type Props = {
  user: UserPublicData;
  appointmentTypes: AppointmentType[];
  paymentPackages: PaymentPackage[];
  loading: boolean;
  onClose?: () => void;
};

export default function AppointmentBooking({ user, loading, appointmentTypes, paymentPackages, onClose }: Props) {
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [showDescription, setShowDescription] = useState<boolean>(false);

  const bookingData = useRef<BookingData | null>(null);
  const handleBookClick = (data: BookingData): void => {
    bookingData.current = data;
    setShowPaymentModal(true);
  };

  const handlePaymentBookClose = (): void => {
    bookingData.current = null;
    setShowPaymentModal(false);
    !!onClose && onClose();
  };

  const appointmentControllerReset = useRef<() => void>();
  const handlePaidSuccess = () => appointmentControllerReset.current && appointmentControllerReset.current();

  return (
    <Fragment>
      <AppointmentBookingController
        user={user}
        appointmentTypes={appointmentTypes}
        loading={loading}
        paymentPackages={paymentPackages}
        onBookClick={handleBookClick}
        onDescriptionClick={() => setShowDescription(true)}
        getResetCallback={(cb) => (appointmentControllerReset.current = cb)}
      />
      {bookingData.current && (
        <PaymentBooking
          {...bookingData.current}
          show={showPaymentModal}
          user={user}
          onClose={handlePaymentBookClose}
          onAppointmentPaidSuccess={handlePaidSuccess}
        />
      )}
      <AppointmentDescriptionModal show={showDescription} onClose={() => setShowDescription(false)} />
    </Fragment>
  );
}
