import { useState } from "react";
import { AppointmentSlot, AppointmentSlotStatus } from "@scrile/api-provider/dist/api/AppointmentSlotProvider";
import providers from "../../../../../../lib/providers";
import emitter, { EVENTS } from "../../../../../../lib/emitter";
import useTimezone from "../../../../../../hooks/useTimezone";
import { NotificationData } from "../../../../../../components/PushManager";

const useViewController = () => {
  const [saving, setSaving] = useState<boolean>(false);
  const [interactedSlots, setInteractedSlots] = useState<Record<string, boolean>>({});

  const { displayTimezone } = useTimezone();

  const handleSave = async () => {
    try {
      setSaving(true);
      const data: { startTime: string; reserved: boolean }[] = [];
      for (const interactedSlotsKey in interactedSlots) {
        data.push({
          startTime: interactedSlotsKey,
          reserved: interactedSlots[interactedSlotsKey],
        });
      }
      await providers.AppointmentSlotProvider.reserve({ data });
      emitter.emit<NotificationData>(EVENTS.NOTIFICATION_SHOW, { text: "Changes saved", image: { icon: "done" } });
    } finally {
      setSaving(false);
    }
  };
  const handleSlotSelect = (slot: AppointmentSlot | null): void => {
    if (!slot || slot.status === AppointmentSlotStatus.BOOKED) return;
    if (Object.keys(interactedSlots).includes(slot.startTime)) {
      setInteractedSlots({
        ...interactedSlots,
        [slot.startTime]: !interactedSlots[slot.startTime],
      });
    } else {
      setInteractedSlots({
        ...interactedSlots,
        [slot.startTime]: slot.status !== AppointmentSlotStatus.RESERVED,
      });
    }
  };

  const handleSetInteractedSlots = (slots: AppointmentSlot[], value: boolean): void => {
    const localInteractedSlots: Record<string, boolean> = {};
    slots.forEach((slot) => {
      if (slot.status === AppointmentSlotStatus.BOOKED) return;
      localInteractedSlots[slot.startTime] = value;
    });
    setInteractedSlots({
      ...interactedSlots,
      ...localInteractedSlots,
    });
  };

  return {
    displayTimezone,
    interactedSlots,
    saving,
    handleSlotSelect,
    handleSave,
    handleSetInteractedSlots,
  };
};

export default useViewController;
