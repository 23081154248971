import React from "react";
import { I18n } from "../../locales";
import { QuickTip } from "../../types";
import BaseIcon from "../BaseIcon";
import "./style.scss"

interface Props {
  item: QuickTip;
  type?: "warning" | "info" | "success";
  className?: string;
}

export default function QuickTipsItem({ item, type = "info", className }: Props) {
  const cls = ["quick-tips-item" , "body", "__" + type];
  if (className) cls.push(className);
  if (item.className) cls.push(item.className);
  const Wrapper = ({ children }: React.PropsWithChildren<{}>) =>
    item.href ? (
      <a className={cls.join(" ")} href={item.href} target="_blank" rel="noopener noreferrer" >
        {children}
      </a>
    ) : (
      <div className={cls.join(" ")}>{children}</div>
    );

  return (
    <Wrapper>
      <BaseIcon className="quick-tips-item__icon" size={24}>
        {item.icon}
      </BaseIcon>
      <I18n path={item.translateKey} />
    </Wrapper>
  );
}
