import {useCallback, useEffect, useState} from "react";
import { InputSliderProps } from "react-input-slider";

interface VolumeSettings {
  muted: boolean;
  level: number;
  lastLevel: number;
}

const styles = {
  track: {
    "height": "100%",
    "width": 4,
    "backgroundColor": "var(--palette-bg-input)",
    "&:active > div:first-of-type": { backgroundColor: "var(--palette-hover-blue)" },
    "&:active > div > div": {
      boxShadow: "inset 0 0 0 1px var(--palette-hover-blue), 0 0 5px rgba(var(--rgb-palette-black-gray), 0.25)",
    },
  },
  active: {
    backgroundColor: "var(--palette-blue)",
    transition: "background-color 0.1s linear",
  },
  thumb: {
    "width": 12,
    "height": 12,
    "boxShadow": "0px 0px 5px rgba(var(--rgb-palette-black-gray), 0.25)",
    "transition": "box-shadow 0.1s linear",
    "&:hover": {
      boxShadow: "inset 0 0 0 1px var(--palette-blue), 0 0 5px rgba(var(--rgb-palette-black-gray), 0.25)",
    },
  },
};

const useController = (player: HTMLVideoElement) => {
  const storageFieldName = "volumeSettings";
  const initialVolume = 0.5;

  const [volumeSettings, setVolumeSettings] = useState<VolumeSettings>({
    muted: player.muted,
    level: player.muted ? 0 : initialVolume,
    lastLevel: 1,
  });

  const update = useCallback((settings: VolumeSettings) => {
    player.volume = settings.level;
    player.muted = settings.muted;
    localStorage.setItem(storageFieldName, JSON.stringify(settings));
    setVolumeSettings(settings);
  }, [player]);

  const onVolumeClick = () => {
    const newSettings = {
      muted: !volumeSettings.muted,
      level: !volumeSettings.muted ? 0 : volumeSettings.lastLevel,
      lastLevel: volumeSettings.level === 0 ? initialVolume : volumeSettings.level,
    };
    update(newSettings);
  };

  const onVolumeChange = (values: InputSliderProps) => {
    if (!values.y) return;
    const volumeValue = values.y <= 0.08 ? 0 : values.y; // react-input-slider issue with 0 value fix
    const newSettings = {
      ...volumeSettings,
      muted: !volumeValue,
      level: +volumeValue.toFixed(2),
    };
    update(newSettings);
  };

  useEffect(() => {
    const volume = player.volume;
    const muted = player.muted;
    let storedVolumeSettings = localStorage.getItem(storageFieldName);
    let newSettings;

    if (storedVolumeSettings) {
      const storedSettings: VolumeSettings = JSON.parse(storedVolumeSettings);
      newSettings = {
        level: muted ? 0 : storedSettings.level,
        muted: storedSettings.muted || muted,
        lastLevel: storedSettings.level === 0 ? initialVolume : storedSettings.level,
      };
    } else {
      newSettings = {
        muted: muted,
        level: muted ? 0 : volume,
        lastLevel: volume === 0 ? initialVolume : volume,
      };
    }
    update(newSettings);
  }, [player, update]);

  return {
    styles,
    volumeSettings,
    onVolumeClick,
    onVolumeChange,
  };
};

export default useController;
