import React from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "../../locales";
import { isPerformer } from "../../lib/usersHelper";
import PerformerProfileCard from "./components/PerformerProfileCard";
import PerformerReviewsCard from "./components/PerformerReviewsCard";
import BaseButton from "../../components/BaseButton";
import BaseSpinner from "../../components/BaseSpinner";
import BasePage from "../../components/BasePage";
import ContentHeader from "../../components/ContentHeader";
import AddToFavorite from "../../components/AddToFavorite";
import useViewController from "./viewController";
import useCustomDomain from "../../hooks/useCustomDomain";
import useAuthUser from "../../hooks/useAuthUser";
import useAppointments from "../../hooks/useAppointments";
import PerformerVideo from "./components/PerformerVideo";
import PerformerVideoModal from "./components/PerformerVideoModal";
import ModalForMobile from "./components/ModalForMobile";
import AppointmentBooking from "./components/AppointmentBooking";
import "./style.scss";

interface Props {
  username: string;
}

function PagePerformerProfile({ username }: Props) {
  const { isCustomDomain } = useCustomDomain();
  const { user: loggedUser } = useAuthUser();

  const {
    user,
    showMobileBook,
    setShowMobileBook,
    video,
    videoLoading,
    showVideoModal,
    setShowVideoModal,
    reviewsLoading,
    mobileReviewsMode,
    setMobileReviewsMode,
    isTablet,
    onReviewAdded,
    reviewItems,
    getNextReviewsPage,
    enableReviewsScroll,
    totalReviewsRecords,
    canBookAppointment,
  } = useViewController(username);
  const { t } = useTranslation();
  const { loading: appointmentsLoading, appointmentTypes, paymentPackages } = useAppointments(user?.id);

  const isAuthor = loggedUser?.id === user?.id;

  if (user && !isPerformer(user)) {
    return <Redirect to={"/"} />;
  }

  if (user) {
    const prepend = (
      <>
        <div className="page-performer-profile__header-wrapper">
          <ContentHeader className="page-performer-profile__screen-name-header text-left">
            {user.screenName}
          </ContentHeader>
          {!isCustomDomain && (
            <div className="page-performer-profile__status-line">
              {!isTablet && (
                <AddToFavorite className="page-performer-profile__favorite mt-n2" withText={false} user={user} />
              )}
            </div>
          )}
        </div>
        {video?.fileData && !isTablet && (
          <PerformerVideo
            className="mb-8"
            user={user}
            video={video}
            loading={videoLoading}
            onClick={() => setShowVideoModal(true)}
          />
        )}
        <div className="page-performer-profile__switch-buttons">
          <BaseButton
            name="about"
            className={"page-performer-profile__switch-button body-big" + (!mobileReviewsMode ? " __active" : "")}
            onClick={() => setMobileReviewsMode(false)}
            opacity
            large
          >
            {t("About")}
          </BaseButton>
          <BaseButton
            name="reviews"
            className={"page-performer-profile__switch-button body-big" + (mobileReviewsMode ? " __active" : "")}
            onClick={() => setMobileReviewsMode(true)}
            opacity
            large
          >
            {t("Reviews")}
            <span className="page-performer-profile__total-reviews small ml-1">{totalReviewsRecords}</span>
          </BaseButton>
        </div>
      </>
    );
    const side = (
      <div>
        {video?.fileData && isTablet && (
          <PerformerVideo
            className="mb-8"
            user={user}
            video={video}
            loading={videoLoading}
            onClick={() => setShowVideoModal(true)}
          />
        )}
        {loggedUser && canBookAppointment && !isAuthor && (
          <ModalForMobile show={showMobileBook} onClose={() => setShowMobileBook(false)}>
            <AppointmentBooking
              user={user}
              appointmentTypes={appointmentTypes}
              loading={appointmentsLoading}
              paymentPackages={paymentPackages}
              onClose={() => setShowMobileBook(false)}
            />
          </ModalForMobile>
        )}
      </div>
    );
    return (
      <BasePage className="page-performer-profile" prepend={prepend} side={side} revers={true}>
        {(!mobileReviewsMode || isTablet) && (
          <PerformerProfileCard
            className="page-performer-profile__profile-card"
            user={user}
            hasAppointment={appointmentTypes.length > 0 && !!loggedUser}
            onBookClick={() => setShowMobileBook(true)}
          />
        )}
        {(mobileReviewsMode || isTablet) && (
          <PerformerReviewsCard
            user={user}
            onReviewAdded={onReviewAdded}
            loading={reviewsLoading}
            items={reviewItems}
            enableScroll={enableReviewsScroll}
            getNextPage={getNextReviewsPage}
            totalRecords={totalReviewsRecords}
          />
        )}
        {video && <PerformerVideoModal show={showVideoModal} video={video} onClose={() => setShowVideoModal(false)} />}
      </BasePage>
    );
  } else {
    return (
      <div className="page-performer-profile__spinner-container">
        <BaseSpinner size={45} />
      </div>
    );
  }
}

export default PagePerformerProfile;
