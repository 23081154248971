import React from "react";
import { AppointmentSlot, AppointmentSlotStatus } from "@scrile/api-provider/dist/api/AppointmentSlotProvider";
import { useTranslation } from "../../../../../../locales";
import CustomScrollBar from "../../../../../../components/CustomScrollBar";
import BaseSpinner from "../../../../../../components/BaseSpinner";
import BaseHint from "../../../../../../components/BaseHint";
import AppointmentSlotView from "../AppointmentSlotView";
import "./style.scss";

type Props = {
  className?: string;
  loading?: boolean;
  isManageMySchedule?: boolean;
  slots: AppointmentSlot[];
  interactedSlots?: Record<string, boolean>;
  labelColorClass?: string;
  onSlotChange?: (slot: AppointmentSlot | null) => void;
};

const MIN_CONTAINER_HEIGHT = "130px";

export default function AppointmentSlotList({
  className,
  slots,
  loading = false,
  isManageMySchedule = false,
  interactedSlots,
  onSlotChange,
  labelColorClass,
}: Props) {
  const cls = ["appointment-slot-list"];
  if (className) cls.push(className);

  const { t } = useTranslation();

  const hasSlots = slots.length > 0;
  const enableHint = (slot: AppointmentSlot) => slot.status === AppointmentSlotStatus.TOO_SHORT && !isManageMySchedule;

  const getSlotClasses = (slot: AppointmentSlot) => {
    const getManagementClasses = () => {
      if (slot.status === AppointmentSlotStatus.BOOKED) return " __booked-slot";
      if (interactedSlots && typeof interactedSlots[slot.startTime] === "boolean") {
        if (interactedSlots[slot.startTime]) {
          return " __reserved-slot";
        } else {
          return "";
        }
      }
      if (slot.status === AppointmentSlotStatus.RESERVED) return " __reserved-slot";
      return "";
    }
    const getBookingClasses = () => {
      let classes = ""
      if (slot.status !== AppointmentSlotStatus.AVAILABLE) classes += " __booked-slot";
      
      if (interactedSlots && typeof interactedSlots[slot.startTime] === "boolean") {
        if (interactedSlots[slot.startTime]) {
          classes += " __selected-slot";
        } else {
          classes = ""
        }
      }
      return classes;
    }
    
    if (isManageMySchedule) {
      return getManagementClasses()
    } else {
      return getBookingClasses()
    }
  };

  return (
    <CustomScrollBar className={cls.join(" ")} style={{ minHeight: MIN_CONTAINER_HEIGHT }}>
      {loading && <BaseSpinner className="ma-auto" dataTestId="appointment-slot-spinner" />}
      {!loading && !hasSlots && <span className="appointment-slot-list__empty-text">{t("No available slots")}</span>}
      {!loading && hasSlots && (
        <ul className="appointment-slot-list__content" data-testid="appointment-slot-list">
          {slots.map((slot, index, arr) => (
            <li key={"key-" + slot.startTime}>
              <BaseHint
                enableHint={enableHint(slot)}
                text={t("Select a time sufficient for the entire session")}
                position={"bottom"}
              >
                <AppointmentSlotView
                  className={getSlotClasses(slot)}
                  labelColorClass={labelColorClass}
                  slot={slot}
                  onClick={(slot) => !!onSlotChange && onSlotChange(slot)}
                />
              </BaseHint>
            </li>
          ))}
        </ul>
      )}
    </CustomScrollBar>
  );
}
