import React, { useState } from "react";
import { Appointment } from "@scrile/api-provider/dist/api/AppointmentProvider";
import { t } from "../../../../../../locales";
import BasePaginator from "../../../../../../components/BasePaginator";
import PageLoading from "../../../../../../components/ElementLoading";
import BaseButton from "../../../../../../components/BaseButton";
import AppointmentList from "../AppointmentList";
import useController, { AppointmentsTimeRange } from "./controller";
import "./style.scss";
import AppointmentDescriptionModal from "../../../../../../modals/AppointmentDescriptionModal";

interface AppointmentsRangeProps extends AppointmentsTimeRange {
  hideStatus?: boolean;
  almostStartedAppointmentId?: Appointment["id"];
}

export default function AppointmentsRange({
  almostStartedAppointmentId,
  startTimeFrom,
  startTimeTo,
  endTimeFrom,
  endTimeTo,
  sort = "START_TIME",
  hideStatus,
}: AppointmentsRangeProps) {
  const [showDescription, setShowDescription] = useState<boolean>(false);

  const { totalPages, appointments, processing, onPageChanged, isCoach, onCancelSuccess } = useController({
    startTimeTo,
    startTimeFrom,
    endTimeFrom,
    endTimeTo,
    sort,
  });

  return (
    <div className="appointments-range">
      <div className="appointments-range__content">
        {processing ? (
          <PageLoading loading={processing} />
        ) : (
          <AppointmentList
            className="appointments-range__list"
            appointments={appointments}
            isCoach={isCoach}
            onCancelSuccess={onCancelSuccess}
            almostStartedAppointmentId={almostStartedAppointmentId}
            hideStatus={hideStatus}
          />
        )}
      </div>

      <div className="appointments-range__footer">
        <BaseButton
          className="appointment-booking__link-button link px-1 ml-n1"
          borderless
          onClick={() => setShowDescription(true)}
        >
          {t("How it works?")}
        </BaseButton>
        <BasePaginator
          className="appointments-range__paginator"
          totalPages={totalPages}
          currentPageChanged={onPageChanged}
        />
      </div>

      <AppointmentDescriptionModal
        show={showDescription}
        isCoach={isCoach}
        onClose={() => setShowDescription(false)}
      />
    </div>
  );
}
