import React from "react";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { useTranslation } from "../../../../locales";
import { AppointmentUserData, MediaConstraints } from "../../../../types";
import ChatTimer from "../../../../components/ChatTimer";
import DotFlashingAnimation from "../DotFlashingAnimation";
import StreamView from "../../../../components/StreamView";
import useViewController from "./viewController";
import AppointmentStreamView from "../AppointmentStreamView";
import EndAppointmentScreen from "../EndAppointmentScreen";

interface Props {
  loading: boolean;
  token: string;
  threadId: string | undefined;
  userList: LiveChatUser[];
  produceData: AppointmentUserData;
  consumeData: AppointmentUserData;
  audioInputs?: MediaDeviceInfo[];
  videoInputs?: MediaDeviceInfo[];
  isProducerUser: boolean;
  isProducerFinishedStream: boolean;
  streamStartedDate: string;
  streamEndedDate: string;
  onClose: () => void;
  onCloseStream: () => void;
  onProduceFinishedStream: () => Promise<void>;
  onChangeConstraints: (constraints: MediaConstraints) => void;
  slotControlButtons?: JSX.Element;
}

export default function AppointmentView({
  loading,
  token,
  threadId,
  userList,
  produceData,
  consumeData,
  audioInputs,
  videoInputs,
  isProducerUser,
  isProducerFinishedStream,
  streamStartedDate,
  streamEndedDate,
  onClose,
  onCloseStream,
  onProduceFinishedStream,
  onChangeConstraints,
  slotControlButtons,
}: Props) {
  const { t } = useTranslation();
  const { isChatEnded, onCloseChat, isPerformer, secondPlayer, setSecondPlayer } = useViewController(
    streamEndedDate,
    userList,
    onCloseStream,
    onProduceFinishedStream
  );

  const disabledVideo = isChatEnded || userList.length < 2 || isProducerFinishedStream;
  const slotVideoScreen = (() => {
    const arr: JSX.Element[] = [];
    if ((isChatEnded || isProducerFinishedStream) && consumeData.user) {
      arr.push(<EndAppointmentScreen key="end-screen" produceUser={consumeData.user} onClose={onClose} />);
    } else if (userList.length < 2) {
      arr.push(<DotFlashingAnimation key="dot-animation" />);
    }
    if (!disabledVideo) {
      arr.push(
        <AppointmentStreamView
          key="view"
          produceData={produceData}
          consumeData={consumeData}
          setPlayer={setSecondPlayer}
        />
      );
    }

    return <>{arr.map((el) => el)}</>;
  })();

  const slotTimer = (
    <ChatTimer
      hideTimer={!isPerformer || isChatEnded}
      streamStartedDate={streamStartedDate}
      grayRecordIcon={isChatEnded}
    >
      {t("Private chat")}
    </ChatTimer>
  );

  const slotContentConfirmationExit = (
    <>
      <h1 className="headline-1 text-center mb-6">{t("Do you want to end the appointment?")}</h1>
      <p className="body text-center">{t("The appointment cannot be resumed after completion.")}</p>
    </>
  );

  return (
    <StreamView
      className="appointment-view"
      loading={loading}
      forceHideControlButtons={isChatEnded}
      disableUserList
      disabledVideo={disabledVideo}
      constraints={consumeData.constraints}
      managedConstraints={produceData.constraints}
      consume
      consumeUser={produceData.user}
      isProducerUser={isProducerUser}
      produceUser={consumeData.user}
      streamStartedDate={streamStartedDate}
      threadId={threadId}
      token={token}
      userList={userList}
      audioInputs={audioInputs}
      videoInputs={videoInputs}
      externalPlayer={secondPlayer}
      produce
      showConfirmationExit
      fullLeftSide
      onChangeConstraints={onChangeConstraints}
      onClose={onCloseChat}
      slotTimer={slotTimer}
      slotContentConfirmationExit={slotContentConfirmationExit}
      slotVideoScreen={slotVideoScreen}
      slotControlButtons={slotControlButtons}
    />
  );
}
