import React, { useEffect, useState } from "react";
import { add, getDayOfYear } from "date-fns";
import { AppointmentSlotStatus } from "@scrile/api-provider/dist/api/AppointmentSlotProvider";
import { t } from "../../../../../../locales";
import BaseButton from "../../../../../../components/BaseButton";
import AppointmentSlotList from "../../../../../PagePerformerProfile/components/AppointmentBooking/components/AppointmentSlotList";
import AppointmentCalendar from "../../../../../PagePerformerProfile/components/AppointmentBooking/components/AppointmentCalendar";
import PageLoading from "../../../../../../components/ElementLoading";
import useViewController from "./viewController";
import useAppointmentSlot from "../../../../../PagePerformerProfile/hooks/useAppointmentSlots";
import useAppointmentBook from "../../../../../PagePerformerProfile/components/AppointmentBooking/components/AppointmentBookingController/hooks/useAppointmentBook";
import { RangeTime } from "../../../../../PagePerformerProfile/components/AppointmentBooking/types";
import useAppointments from "../../../../../../hooks/useAppointments";
import useAuthUser from "../../../../../../hooks/useAuthUser";
import GoogleCalendarSync from "../../../../../../components/GoogleCalendarSync";
import "./style.scss";

const AppointmentsReserve = () => {
  const { user } = useAuthUser();
  const {
    displayTimezone,
    interactedSlots,
    saving,
    handleSlotSelect,
    handleSetInteractedSlots,
    handleSave,
  } = useViewController();

  const { appointmentTypes, loading } = useAppointments(user?.id);

  const {
    keyOfActiveDay,
    selectedRangeTime,
    setBookTime,
    setKeyOfActiveDay,
    getAppointmentTypeId,
  } = useAppointmentBook(appointmentTypes);
  const { loading: slotLoading, appointmentSlotMap, getAppointmentSlots } = useAppointmentSlot();

  const [hasAvailableSlot, setHasAvailableSlot] = useState(true);
  
  useEffect(() => {
    if (appointmentSlotMap && appointmentSlotMap[keyOfActiveDay]?.length > 0) {
      setHasAvailableSlot(
        appointmentSlotMap[keyOfActiveDay].some((slot) => {
          if (typeof interactedSlots[slot.startTime] !== "boolean") {
            return slot.status === AppointmentSlotStatus.AVAILABLE || slot.status === AppointmentSlotStatus.TOO_SHORT;
          } else {
            return !interactedSlots[slot.startTime];
          }
        })
      );
    }
  }, [appointmentSlotMap, keyOfActiveDay, interactedSlots]);

  const handleSelectAll = (val: boolean) => {
    if (appointmentSlotMap && appointmentSlotMap[keyOfActiveDay]?.length > 0) {
      handleSetInteractedSlots(appointmentSlotMap[keyOfActiveDay], val);
    }
  };

  const handleWeekChange = (range: RangeTime): void => {
    selectedRangeTime.current = range;
    setBookTime(null);
    getAppointmentSlots({
      startTime: range.startTime.toISOString(),
      endTime: add(range.endTime, { days: 1 }).toISOString(),
      appointmentTypeId: getAppointmentTypeId(),
      userId: user?.id ?? "0",
      includeBooked: true,
      includeReserved: true,
    });
  };
  const handleDaySelect = (day: Date): void => {
    setKeyOfActiveDay(getDayOfYear(day));
    setBookTime(null);
  };

  return (
    <div className="appointments-types d-flex __column ml-auto mr-auto">
      {loading ? (
        <PageLoading loading={loading} />
      ) : (
        <>
          <div className="appointments-types__timezone body caption mb-3">
            {t("Your timezone")}
            <span className="fw-semibold caption__mark"> {displayTimezone}</span>
          </div>
          <div className="appointments-types__description body caption mb-3">
            <span>{t("Mark time slots when you won’t be available to coach and save your choice.")}</span>
            <span>{t("If your Google Calendar is synced, make sure to edit your availability time first in Google Calendar and then on the SecureTheOffer platform.")}</span>
          </div>
          <AppointmentCalendar
            loading={slotLoading}
            appointmentSlotMap={appointmentSlotMap}
            onChangeWeek={handleWeekChange}
            onSelectDay={handleDaySelect}
          />
          <AppointmentSlotList
            isManageMySchedule
            slots={appointmentSlotMap?.[keyOfActiveDay] ?? []}
            loading={slotLoading}
            interactedSlots={interactedSlots}
            labelColorClass={"__transparent"}
            onSlotChange={handleSlotSelect}
          />
          {appointmentSlotMap && appointmentSlotMap[keyOfActiveDay]?.length > 0 &&
            <BaseButton className="appointments-types__selectAll mb-5 mt-2" outline onClick={() => handleSelectAll(hasAvailableSlot)}>
              {t("Mark all as {{type}}", {
                type: hasAvailableSlot ? t("unavailable") : t("available"),
              })}
            </BaseButton>
          }
          
          <div className="appointments-types__legend d-flex body">
            <div className="__unavailable">{t("Marked as unavailable")}</div>
            <div className="__booked">{t("Reserved appointment")}</div>
          </div>
          <div className="appointments-types__save-button-wrapper d-flex">
            <GoogleCalendarSync />
            <BaseButton className="appointments-types__save-button" large onClick={handleSave} loading={saving}>
              {t("Save Changes")}
            </BaseButton>
          </div>
        </>
      )}
    </div>
  );
};

export default AppointmentsReserve;
