import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { FragmentFields } from "@scrile/api-provider/dist/api/types";
import { Review } from "@scrile/api-provider/dist/api/ReviewProvider";
import { FileData } from "@scrile/api-provider/dist/api/FileProvider";
import { checkRole } from "@scrile/tools/dist/lib/SecurityHelpers";
import config from "../../config";
import { MediaFilesController } from "../../lib/MediaFileController";
import { useFindByUserName } from "../../hooks/useFindByUserName";
import usePager from "../../hooks/usePager";
import useAuthUser from "../../hooks/useAuthUser";
import providers from "../../lib/providers";

const searchFields: FragmentFields<UserPublicData> = [
  "id",
  "role",
  "username",
  "screenName",
  "disabled",
  { onlineStatus: ["isOnline"] },
  { publicLivechatUser: ["broadcasting"] },
  { avatar: ["urlPart"] },
  { questionary: ["fields", { tags: ["id", "slug", "title", "categoryId"] }] },
];

export interface ProfileVideoData {
  fileData: FileData;
  thumbnailUrl: string;
}

function useController(username: string) {
  const history = useHistory();
  const [video, setVideo] = useState<ProfileVideoData>();
  const [videoLoading, setVideoLoading] = useState(true);
  const [reviewsLoading, setReviewsLoading] = useState(true);
  const { user: authUser } = useAuthUser();
  const { user } = useFindByUserName(
    username,
    searchFields,
    useCallback(() => {
      history.push("/");
    }, [history])
  );

  useEffect(() => {
    (async () => {
      if (!user?.id) return;

      try {
        setVideoLoading(true);
        const response = await providers.FileProvider.find({
          fileIds: [{ subjectId: user?.id || "", type: "profile_video" }],
        });
        let thumbnailUrl = "";
        if (response.length) thumbnailUrl = MediaFilesController.getVideoThumbnail(response[0].urlPart, "1080p");
        setVideo({ fileData: response[0], thumbnailUrl: thumbnailUrl });
      } finally {
        setVideoLoading(false);
      }
    })();
  }, [user]);

  const { enableScroll, items, getNextPage, getFirstPage, data, setData } = usePager(
    useCallback(
      (page) =>
        providers.ReviewProvider.find({
          data: {
            subjects: [{ subjectId: user?.id || "", subjectType: "user" }],
            sort: "TIME,DESC",
            page,
            size: 6,
          },
        }),
      [user]
    )
  );

  const onReviewAdded = (review: Review) => {
    const localData = items;

    if (!data || items.length === 0) {
      getFirstPage();
    } else {
      if (data.hasNextPage) {
        localData.pop();
      }
      localData.unshift(review);
      setData((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            totalRecords: prevState.totalRecords + 1,
            result: localData,
          };
        }
      });
    }
  };

  useEffect(() => {
    if (user?.id) {
      (async () => {
        setReviewsLoading(true);
        await getFirstPage();
        setReviewsLoading(false);
      })();
    }
  }, [user, getFirstPage]);

  const canBookAppointment = useMemo(() => checkRole(authUser?.role ?? "", config.userJoinRole), [authUser]);

  return {
    user,
    video,
    videoLoading,
    reviewsLoading,
    onReviewAdded,
    reviewItems: items,
    getNextReviewsPage: getNextPage,
    enableReviewsScroll: enableScroll,
    totalReviewsRecords: data?.totalRecords.toString(),
    canBookAppointment,
  };
}

export default useController;
