import React from "react";
import { Link } from "react-router-dom";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import useAuthUser from "../../../../hooks/useAuthUser";
import { isPerformer } from "../../../../lib/usersHelper";
import { useTranslation } from "../../../../locales";
import BaseAvatar from "../../../../components/BaseAvatar";
import AddToFavorite from "../../../../components/AddToFavorite";
import BaseButton from "../../../../components/BaseButton";
import BaseIcon from "../../../../components/BaseIcon";
import LeaveReviewModal from "../../../../modals/LeaveReviewModal";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  produceUser: UserPublicData;
  onClose: () => void;
}

export default function EndAppointmentScreen({ produceUser, onClose }: Props) {
  const { t } = useTranslation();
  const { showReviewModal, onShowReviewModal, onCloseReviewModal } = useViewController();
  const { user } = useAuthUser();

  return (
    <div className="end-appointment-screen">
      <div className="end-appointment-screen__container">
        {!isPerformer(user) && (
          <>
            <div className="end-appointment-screen__user mb-3">
              <Link to={`/${produceUser.username}`}>
                <BaseAvatar className="mr-3" size={40} user={produceUser} />
                <div className="end-appointment-screen__user-name body fw-semibold">{produceUser.screenName}</div>
              </Link>
            </div>
            <AddToFavorite
              className="end-appointment-screen__favourite-btn __transparent fw-normal"
              user={produceUser}
              withText
            />
            <BaseButton
              className="end-appointment-screen__review-btn __transparent fw-normal"
              onClick={onShowReviewModal}
              borderless
            >
              <BaseIcon className="mr-2">write</BaseIcon>
              {t("Leave a review")}
            </BaseButton>
          </>
        )}
        {isPerformer(user) && (
          <div className="end-appointment-screen__text body-big fw-normal">{t("The appointment is over")}</div>
        )}
        <BaseButton
          className="end-appointment-screen__close-btn mt-4"
          onClick={onClose}
        >
          {t("Go Home")}
        </BaseButton>
      </div>
      <LeaveReviewModal show={showReviewModal} onClose={onCloseReviewModal} recipientUser={produceUser} />
    </div>
  );
}
