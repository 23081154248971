import { useCallback, useState } from "react";
import providers from "../../lib/providers";
import { t } from "../../locales";

enum GoogleCalendarState {
  LOADING = "loading",
  SYNCED = "synced",
  NEW = "new",
}

function useController() {
  const returnUrl = new URL("/integrations/google/calendar.html", window.location.origin).toString();

  const [googleCalendarState, setGoogleCalendarState] = useState<GoogleCalendarState>(GoogleCalendarState.LOADING);
  const [googleCalendarURL, setGoogleCalendarURL] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  
  const removeGoogleCalendarSync = async () => {
    try {
      setLoading(true);
      await providers.GoogleCalendarProvider.delete();
      setGoogleCalendarState(GoogleCalendarState.NEW);
      await getData();
    } finally {
      setLoading(false);
    }
  };

  const handleStorageUpdate = useCallback(async (e: StorageEvent) => {
    if (e.key !== "GoogleCalendarSyncCode" || !e.newValue) return;
    const createGoogleCalendarSync = async (code: string) => {
      try {
        setLoading(true);
        await providers.GoogleCalendarProvider.assign({ data: { code, returnUrl } });
        setGoogleCalendarState(GoogleCalendarState.SYNCED);
        localStorage.removeItem("GoogleCalendarSyncCode");
      } finally {
        setLoading(false);
      }
    };
    
    await createGoogleCalendarSync(e.newValue);
  }, [returnUrl]);

  const getButtonText = () => {
    if (googleCalendarState === GoogleCalendarState.NEW) {
      return t("Sync with Google Calendar");
    }
    if (googleCalendarState === GoogleCalendarState.SYNCED) {
      return t("Disconnect Google Calendar");
    }
    return t("Can't connect to Google Calendar");
  };

  const onClick = async () => {
    if (googleCalendarState === GoogleCalendarState.SYNCED) {
      await removeGoogleCalendarSync();
    }
    if (googleCalendarState === GoogleCalendarState.NEW && googleCalendarURL) {
      window.open(googleCalendarURL, "_blank");
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const result = await providers.GoogleCalendarProvider.start({ data: { returnUrl } });
      setGoogleCalendarURL(result.url);
      setGoogleCalendarState(result.url === null ? GoogleCalendarState.SYNCED : GoogleCalendarState.NEW);
    } finally {
      setLoading(false);
    }
  };
  return {
    handleStorageUpdate,
    loading,
    getData,
    onClick,
    getButtonText,
  };
}

export default useController;
