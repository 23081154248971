export const MINUTES_IN_DAY = 1440;
export const MINUTES_IN_WEEK = 10080;
export const DEFAULT_BEGIN_MINUTES = 540;
export const DEFAULT_DURATION_MINUTES = 540;

export interface UserAvailabilityDisplayInterval {
  beginTime: {
    hours: string;
    minutes: string;
  };
  endTime: {
    hours: string;
    minutes: string;
  };
  dayOfWeek: DayOfWeek;
  index: number;
}

export interface InputInterval {
  displayIntervals: UserAvailabilityDisplayInterval[];
  newTime: string;
  index: number;
}

export enum DayOfWeek {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}
