import React from "react";
import { useParams } from "react-router-dom";
import { LivechatSubjectType } from "@scrile/api-provider/dist/api/LivechatsProvider";
import BasePage from "../../components/BasePage";
import AppointmentStreamController from "./components/AppointmentStreamController";
import useViewController from "./viewController";
import "./style.scss";

export default function PageAppointmentChat() {
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const { onClose, appointment, owner, authUser, key } = useViewController(appointmentId);

  if (!authUser || !appointment) return null;
  return (
    <BasePage className="page-appointment-chat">
      <AppointmentStreamController
        key={key}
        owner={owner}
        appointment={appointment}
        produceUser={appointment.provider}
        consumeUser={appointment.consumer}
        livechatSubject={{ subjectId: appointmentId, subjectType: LivechatSubjectType.APPOINTMENT }}
        onClose={onClose}
      />
    </BasePage>
  );
}
